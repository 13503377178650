import React, { useState } from 'react';
import {
  MainTitle,
  isSessionExpired,
  Pages,
  SaVaBreakfastInfo,
  NergisFishGalataInfo,
  LobaInfo,
  MaskulenInfo,
  KarakediInfo,
  WorkSpaceInfo,
  ClinidentInfo,
} from '../../../helpers';
import { OffersCompList } from '../../../components/OffersCompList';
import { Warning, DiscountCode } from '../../../components';
import {
  sava,
  petshop,
  nergis_fish_galata,
  loba,
  kuafor,
  okeep_ofice,
  clinident,
} from '../../../assets/images/footer/offers/icons/icon';

const OffersList = [
  {
    iconSrc: sava,
    title: SaVaBreakfastInfo.TITLE,
    discount: `${SaVaBreakfastInfo.DISCOUNT} `,
    info: SaVaBreakfastInfo,
  },
  {
    iconSrc: nergis_fish_galata,
    title: NergisFishGalataInfo.TITLE,
    discount: `${NergisFishGalataInfo.DISCOUNT} `,
    info: NergisFishGalataInfo,
  },
  {
    iconSrc: loba,
    title: LobaInfo.TITLE,
    discount: `${LobaInfo.DISCOUNT} `,
    info: LobaInfo,
  },
  {
    iconSrc: kuafor,
    title: MaskulenInfo.TITLE,
    discount: `${MaskulenInfo.DISCOUNT} `,
    info: MaskulenInfo,
  },
  {
    iconSrc: petshop,
    title: KarakediInfo.TITLE,
    discount: `${KarakediInfo.DISCOUNT} `,
    info: KarakediInfo,
  },
  {
    iconSrc: okeep_ofice,
    title: WorkSpaceInfo.TITLE,
    discount: `${WorkSpaceInfo.DISCOUNT} `,
    info: WorkSpaceInfo,
  },
  {
    iconSrc: clinident,
    title: ClinidentInfo.TITLE,
    discount: `${ClinidentInfo.DISCOUNT} `,
    info: ClinidentInfo,
  },
];

export const Offers = () => {
  const validHome = localStorage.getItem('ValidHome');
  const showSessionExpired = localStorage.getItem('showSessionExpired');

  if (!validHome && !showSessionExpired) {
    return <Warning></Warning>;
  }

  if (showSessionExpired) {
    return <Warning text='Your 48 hours session has ended.'></Warning>;
  }

  const sessionDate = localStorage.getItem('SessionDate');
  const sessionExpired = isSessionExpired(sessionDate);

  if (sessionExpired) {
    localStorage.clear();
    localStorage.setItem('showSessionExpired', true);
    return <Warning text='Your 48 hours session has ended.'></Warning>;
  }

  localStorage.setItem('FormData', JSON.stringify({}));
  localStorage.setItem('HomeRoute', '/offers');
  localStorage.setItem('activePage', Pages.OFFERS);

  const [offerInfo, setOfferInfo] = useState(false);
  const [discountCodeVisible, setDiscountCodeVisible] = useState(false);

  return (
    <div className='wrapper'>
      {discountCodeVisible ? (
        <DiscountCode setDiscountCodeVisible={setDiscountCodeVisible} offerInfo={offerInfo} />
      ) : (
        <>
          <div className='main-title'>{MainTitle.OFFERS}</div>
          <div className='line-24'></div>
          {OffersList.map((item, index) => (
            <OffersCompList
              key={index}
              iconSrc={item.iconSrc}
              title={item.title}
              discount={item.discount}
              handleClick={() => {
                setOfferInfo(item.info);
                setDiscountCodeVisible(true);
              }}
            />
          ))}
        </>
      )}
    </div>
  );
};
