import React, { useState } from 'react';
import { CloseHeader, Warning, LocationInfo, DiscountInfo } from '../components';

export const DiscountCode = ({ setDiscountCodeVisible, offerInfo }) => {
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }

  const [activeMap, setActiveMap] = useState(false);

  return (
    <>
      <div style={{ marginTop: '30px' }}></div>
      {activeMap ? (
        <LocationInfo
          setActiveMap={setActiveMap}
          locationUrl={offerInfo.LOCATION_URL}
          latitude={offerInfo.LATITUDE}
          longitude={offerInfo.LONGITUDE}
        />
      ) : (
        <div className='content-body'>
          <CloseHeader title={offerInfo.TITLE} handleClick={() => setDiscountCodeVisible(false)} />
          <DiscountInfo setActiveMap={setActiveMap} offerInfo={offerInfo} />
        </div>
      )}
    </>
  );
};
