import React from 'react';
import { Button } from '../../../../components';
import { ExternalLinks } from '../../../../helpers';
import { GMap, MapMarker } from 'react-rainbow-components';

const buttonStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#2C2C2C',
  color: '#fff',
  width: '95%',
  height: '50px',
  borderRadius: '10px',
};

export const HouseLocation = () => {
  const home = JSON.parse(localStorage.getItem('Home'));

  return (
    <div
      style={{
        width: '100%',
        height: '90%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}>
      <div className='map-container'>
        <GMap
          apiKey={ExternalLinks.GOOGLE_MAPS_API_KEY}
          zoom={14}
          latitude={home.geopoint.latitude || 41.032547723545676}
          longitude={home.geopoint.longitude || 28.984288813492117}>
          <MapMarker
            latitude={home.geopoint.latitude || 41.032547723545676}
            longitude={home.geopoint.longitude || 28.984288813492117}
            style={{ display: 'none' }}
            className='rainbow-google-map_marker hidden'
          />
        </GMap>
      </div>

      <Button
        title='See on your map'
        style={buttonStyle}
        handleClick={() =>
          (location.href =
            home.mapUrl ||
            'https://www.google.com/maps/place/Cihangir,+Oba+Sk.+No:+2+D:B,+34433+Beyo%C4%9Flu%2F%C4%B0stanbul/@41.032398,28.984321,17z/data=!3m1!4b1!4m6!3m5!1s0x14cab763add3b875:0x8569662e171eecb5!8m2!3d41.032398!4d28.984321!16s%2Fg%2F11jv3s5ksq?hl=tr-TR&entry=ttu')
        }
      />
    </div>
  );
};
