import React from 'react';

export const HomeInfoProcedure = ({ iconSrc, title, handleClick }) => {
  return (
    <>
      <div className='procedure-container' onClick={handleClick}>
        <div className='procedure-container__icon'>
          <img src={iconSrc} alt='icon' />
        </div>
        <div className='procedure-container__title'>{title}</div>
      </div>
    </>
  );
};
