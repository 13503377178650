import React from 'react';
import { Header, TextButton, InfoBottom, TimeInfo, Warning } from '../../../components';
import { MainTitle, Airports } from '../../../helpers';

export const SelectIncomingAirport = () => {
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }

  const handleClicked = ({ airport }) => {
    localStorage.setItem('Airport', JSON.stringify(airport));
    window.location.href = '/incoming-guest-info';
  };

  return (
    <div className='wrapper'>
      <div className='main-title'>{MainTitle.INCOMING_TRANSFER}</div>
      <div className='content-body'>
        <Header title='Choose Airport' />
        <div className='request-button-group' style={{ height: 'calc(100% - 140px)' }}>
          <div className='request-button-group__title'>Which airport will you use?</div>
          <TextButton
            title='SAW'
            subTitle='Istanbul Sabiha Gokcen'
            onClick={() => handleClicked({ airport: Airports.SAW.CODE })}
          />
          <TextButton
            title='IST'
            subTitle='Istanbul Airport'
            onClick={() => handleClicked({ airport: Airports.IST.CODE })}
          />
          <InfoBottom
            title='Estimated time for arrival to home'
            contentsComp={
              <>
                <TimeInfo title='Istanbul Airport' time='45 min' />
                <TimeInfo title='Istanbul Sabiha Gokcen' time='1 Hour' />
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};
