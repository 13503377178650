import React, { useState } from 'react';
import { InfoModal } from './';

export const Procedure = ({ key, iconSrc, title, ModalComp }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <div className='procedure-container' key={key} onClick={() => setShowModal(true)}>
        <div className='procedure-container__icon'>
          <img src={iconSrc} alt='icon' />
        </div>
        <div className='procedure-container__title'>{title}</div>
      </div>
      <InfoModal
        title={title}
        ModalComp={ModalComp}
        openModal={showModal}
        closeModal={() => setShowModal(false)}
      />
    </>
  );
};
