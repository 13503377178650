import React from 'react';
import { getTimeString, getDateString } from '../helpers';

export const LateCheckoutRequest = ({ setMessage }) => {
  const home = JSON.parse(localStorage.getItem('Home'));
  const formData = JSON.parse(localStorage.getItem('FormData'));
  const timeString = getTimeString(formData.date);
  const dateString = getDateString(formData.date);

  const from = `From: ${home.title}`;
  const request = 'Request: Late Check Out';
  const time = `When: ${timeString} / ${dateString}`;
  const address = `Address: ${home.address}`;
  const message = `${from}\n${request}\n${time}\n${address}`;

  setMessage(encodeURIComponent(message));

  return (
    <div className='request-info'>
      <div className='request-info__text'>Hello Okeep,</div>
      <div className='request-info__text'>
        I would like to request a <span>{timeString}</span> on <span>{dateString}</span>
      </div>
    </div>
  );
};
