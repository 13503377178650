import React from 'react';
import { getTimeString, getDateString } from '../helpers';

export const HamamRequest = ({ setMessage }) => {
  const home = JSON.parse(localStorage.getItem('Home'));
  const formData = JSON.parse(localStorage.getItem('FormData'));
  const timeString = getTimeString(formData.date);
  const dateString = getDateString(formData.date);

  const from = `From: ${home.title}`;
  const request = 'Request: Hamam';
  const name = `Name: ${formData.name}`;
  const time = `Time: ${timeString} / ${dateString}`;
  const male = `Male: ${formData.male}`;
  const female = `Female: ${formData.female}`;
  const extraTransfer = `Extra Transfer: ${formData.checkedTransferService ? 'Yes' : 'No'}`;
  const address = `Address: ${home.address}`;
  const message = `${from}\n${request}\n${name}\n${time}\n${male}\n${female}\n${extraTransfer}\n${address}`;

  setMessage(encodeURIComponent(message));

  return (
    <div className='request-info'>
      <div className='request-info__text'>Hello Okeep,</div>
      <div className='request-info__text'>
        I{"'"}m <span>{formData.name}</span>
      </div>
      <div className='request-info__text'>
        I request a <span>Hamam Service</span>
      </div>
      {formData.checkedTransferService && (
        <div className='request-info__text'>
          Also <span>I want Transfer Service</span>
        </div>
      )}
      <div className='request-info__text'>
        I want to make a reservation at <span>{timeString}</span> on <span>{dateString}</span>
      </div>
      <div className='request-info__text'>
        <span>{formData.male + formData.female}</span> people are coming.
      </div>
    </div>
  );
};
