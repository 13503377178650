export const Rules = () => {
  return (
    <div
      className='description-box'
      style={{ marginTop: '10px', height: 'calc(100% - 70px)', overflow: 'scroll' }}>
      <div className='description-box__description' style={{ padding: '10px', height: '100%' }}>
        <div>
          <div className='description-box__body' style={{ marginBottom: '15px' }}>
            1-Do not exceed the maximum number of guests specified for the apartment.
            <br />
            Noise and Neighbors:
          </div>
          <div className='description-box__body' style={{ marginBottom: '15px' }}>
            2-Please be respectful to our neighbors and avoid making noise during nighttime hours.
            <br />
            Household Items and Cleanliness:
          </div>
          <div className='description-box__body' style={{ marginBottom: '15px' }}>
            3-Take good care of the items in the apartment and avoid causing damage.
            <br />
            Please clean the apartment before use and dispose of your trash properly.
            <br />
            Your Guests:
          </div>
          <div className='description-box__body' style={{ marginBottom: '15px' }}>
            4-Only allow the people you have made reservations for to enter the apartment.
            <br />
            Obtain prior permission to host guests from outside.
            <br />
            Security:
          </div>
        </div>
      </div>
    </div>
  );
};
