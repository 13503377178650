import React from 'react';
import {
  Header,
  NextStepButton,
  SlideComp,
  GuidanceButtonGroup,
  WorkingHours,
  Warning,
} from '../../../../components';
import { MainTitle, openInNewTab, ClinidentInfo, ClinidentWorkingHours } from '../../../../helpers';
import InstagramIcon from '../../../../assets/images/icons/instagram-button.png';
import {
  dental1Img,
  dental2Img,
  dental3Img,
  dental4Img,
} from '../../../../assets/images/footer/special-services/dental-care/image/img';

const slideImages = [
  {
    url: dental1Img,
  },
  {
    url: dental2Img,
  },
  {
    url: dental3Img,
  },
  {
    url: dental4Img,
  },
];

export const DentalClinicInfo = ({ setActiveMap }) => {
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }

  const [hoursPage, setHoursPage] = React.useState(false);

  return (
    <>
      {hoursPage ? (
        <WorkingHours handleClick={() => setHoursPage(false)} hours={ClinidentWorkingHours} />
      ) : (
        <>
          <div className='content-body'>
            <Header
              title='Clinident'
              subTitle='click for working hours'
              subTitleClass='clickable'
              handleClick={() => setHoursPage(true)}
            />
            <div
              className='description-box'
              style={{
                justifyContent: 'flex-start',
                paddingTop: '5px',
                height: 'calc(100% - 110px)',
              }}>
              <div
                className='description-box__description'
                style={{ padding: '5px', height: '100%', overflow: 'scroll', width: '95%' }}>
                <SlideComp slideImages={slideImages} />
                <div>
                  <div className='description-box__header'>
                    We use the latest equipment and technology and have our own digital dental labs
                    above world standards.
                  </div>
                  <div className='description-box__body'>
                    As Clinident, we promise you the best treatment option in the shortest time
                    possible.
                  </div>
                  <div className='description-box__body'>
                    We have selected the highest quality materials, regardless of cost, to provide
                    you with healthy, long-lasting aesthetic results while protecting your dental
                    health. One of the most important factors in dentistry is the longevity and
                    integrity of the treatment. After treatment, your designated Medical Advisor
                    will be available for support 24 hours a day, 7 days a week.
                  </div>
                </div>
              </div>
            </div>
            <GuidanceButtonGroup
              iconButtonSrc={InstagramIcon}
              title='View on map'
              handleClickIcon={() => openInNewTab(ClinidentInfo.INSTAGRAM_URL)}
              handleClickMap={() => setActiveMap(true)}
            />
          </div>
          <NextStepButton routeUrl='/dental-clinic-details' mainTitle={MainTitle.DENTAL} />
        </>
      )}
    </>
  );
};
