import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {
  HomeServices,
  LateCheckout,
  Cleaning,
  RoomSupplies,
  SpecialRequest,
  Support,
  Market,
} from './pages/footer/home-services';
import {
  SpecialServices,
  DentalClinic,
  DentalClinicDetails,
  DentalClinicInfo,
  DentalReservation,
  Hamam,
  HamamDetails,
  HamamInfo,
  HamamReservation,
  UsefulApps,
  Massage,
  MassageDetails,
  MassageInfo,
  MassageRezervation,
  SelectTransfer,
  SelectTaxiTransfer,
  TaxiReservation,
  SelectAirport,
  SelectVehicle,
  SelectDate,
} from './pages/footer/special-services';
import { Offers } from './pages/footer/offers';
import { Language } from './pages/header/language';
import { Info } from './pages/header/info';
import { Request, SessionControl } from './pages/general';
import { IncomingTransfer, SelectIncomingAirport, IncomingGuestInfo } from './pages/incoming';
import { Provider } from './helpers';
import ReactGa from 'react-ga4';

const trackingId = 'G-FE293HSM9C';

function App() {
  if (!window.location.href.includes('localhost')) {
    ReactGa.initialize(trackingId);
    ReactGa.send('pageview', window.location.pathname + window.location.search);
    ReactGa.event({
      category: 'User',
      action: 'User entered the site',
    });
  }

  window.addEventListener('resize', () => {
    const innerHeight = `${window.innerHeight}px`;
    document.documentElement.style.setProperty('--inner-height', innerHeight);
  });
  const innerHeight = `${window.innerHeight}px`;
  document.documentElement.style.setProperty('--inner-height', innerHeight);

  return (
    <Provider className='App'>
      <Routes>
        <Route path='/incoming-guest/:id' element={<SessionControl guestType='incoming' />} />
        <Route path='/incoming-guest' element={<IncomingTransfer />} />
        <Route path='/select-incoming-airport' element={<SelectIncomingAirport />} />
        <Route path='/incoming-guest-info' element={<IncomingGuestInfo />} />
        <Route path='/:id' element={<SessionControl guestType='current' />} />
        <Route path='/' element={<HomeServices />} />
        <Route path='/late-checkout' element={<LateCheckout />} />
        <Route path='/cleaning' element={<Cleaning />} />
        <Route path='/room-supplies' element={<RoomSupplies />} />
        <Route path='/special-request' element={<SpecialRequest />} />
        <Route path='/support' element={<Support />} />
        <Route path='/market' element={<Market />} />
        <Route path='/special-services' element={<SpecialServices />} />
        <Route path='/select-transfer' element={<SelectTransfer />} />
        <Route path='/select-taxi-transfer' element={<SelectTaxiTransfer />} />
        <Route path='/taxi-reservation' element={<TaxiReservation />} />
        <Route path='/select-airport' element={<SelectAirport />} />
        <Route path='/select-vehicle' element={<SelectVehicle />} />
        <Route path='/select-date' element={<SelectDate />} />
        <Route path='/dental-clinic' element={<DentalClinic />} />
        <Route path='/dental-clinic-details' element={<DentalClinicDetails />} />
        <Route path='/dental-clinic-info' element={<DentalClinicInfo />} />
        <Route path='/dental-reservation' element={<DentalReservation />} />
        <Route path='/hamam' element={<Hamam />} />
        <Route path='/hamam-details' element={<HamamDetails />} />
        <Route path='/hamam-info' element={<HamamInfo />} />
        <Route path='/hamam-reservation' element={<HamamReservation />} />
        <Route path='/useful-apps' element={<UsefulApps />} />
        <Route path='/massage' element={<Massage />} />
        <Route path='/massage-details' element={<MassageDetails />} />
        <Route path='/massage-info' element={<MassageInfo />} />
        <Route path='/massage-rezervation' element={<MassageRezervation />} />
        <Route path='/offers' element={<Offers />} />
        <Route path='/language' element={<Language />} />
        <Route path='/home-info' element={<Info />} />
        <Route path='/request' element={<Request />} />
      </Routes>
    </Provider>
  );
}

export default App;
