import React from 'react';
import { getTimeString, getDateString, Vehicles } from '../helpers';

export const TaxiTransferRequest = ({ setMessage }) => {
  const home = JSON.parse(localStorage.getItem('Home'));
  const formData = JSON.parse(localStorage.getItem('FormData'));
  const timeString = getTimeString(formData.date);
  const dateString = getDateString(formData.date);
  const vehicle = JSON.parse(localStorage.getItem('Vehicle'));
  const location = formData.location;

  const from = `From: ${home.title}`;
  const request = 'Request: Taxi Transfer';
  const prefer = `Prefer: ${Vehicles[vehicle].NAME}`;
  const name = `Name: ${formData.name}`;
  const to = `To: ${location}`;
  const when = `When: ${timeString} / ${dateString}`;
  const address = `Home Address: ${home.address}`;
  const message = `${from}\n${request}\n${prefer}\n${name}\n${to}\n${when}\n${address}`;

  setMessage(encodeURIComponent(message));

  return (
    <div className='request-info'>
      <div className='request-info__text'>Hello Okeep,</div>
      <div className='request-info__text'>
        I{"'"}m <span>{formData.name}</span>
      </div>
      <div className='request-info__text'>
        I request a <span>{Vehicles[vehicle].NAME}</span> to go to <span>{location}</span>
      </div>
      <div className='request-info__text'>
        at <span>{timeString}</span> on <span>{dateString}</span>
      </div>
    </div>
  );
};
