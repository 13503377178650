import { Input, Application } from 'react-rainbow-components';

export const TextInput = ({
  handleChange,
  placeholder,
  label,
  value,
  type = 'text',
  style,
  required,
  disabled = false,
  className,
}) => {
  const theme = {
    rainbow: {
      palette: {
        brand: '#FFC42E',
      },
    },
  };

  return (
    <Application theme={theme} className='text-input-container' style={style}>
      <Input
        placeholder={placeholder}
        label={label}
        type={type}
        className={`${className} text-input`}
        isCentered={true}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        error={required ? 'This field is required' : false}
      />
    </Application>
  );
};
