import React from 'react';
import ImageSrc from '../../../../../assets/images/usefulInfo/istanbul-kart.png';
export const IstanbulKartComp = () => {
  return (
    <div className='useful-info-componet'>
      <div className='useful-info-image-container'>
        <img
          src={ImageSrc}
          alt='useful-info-image'
          className='useful-info-image-container__image'
        />
      </div>
      <div className='useful-info-description'>
        In particular, urban transportation made available by the Istanbul Metropolitan
        Municipality;
      </div>
      <div className='useful-info-description'>
        It is a digital card that allows payment in places such as markets, parking lots, social
        facilities.
      </div>
      <div className='useful-info-description'>
        You can easily make online transactions such ad İstanbulkart application, top-up TL/
        subscription and so on.
      </div>
    </div>
  );
};
