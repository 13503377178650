export const RequestButton = ({ text, iconSrc, onClick, className = '' }) => {
  return (
    <>
      <div className={`request-button ${className}`} onClick={onClick}>
        <img className='request-button__icon' src={iconSrc} alt='Request Button' />
        <div className='request-button__title'>{text}</div>
      </div>
    </>
  );
};
