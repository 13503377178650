import React from 'react';
import { zirconiumImg } from '../../../../../assets/images/footer/special-services/dental-care/image/img';

export const ZirconiumModalComp = () => {
  return (
    <div className='modal-comp__body'>
      <div className='modal-comp__body-img'>
        <img src={zirconiumImg} alt='zirconium' />
      </div>
      <div className='modal-comp__body-text'>
        <>
          <p>
            Enjoy your smile with monolithic zirconium crowns that are produced untouched by our new
            generation devices.
          </p>
          <p>Our treatments are guaranteed for 10 years. We provide warranty certificate.</p>
          <p>
            You can get a price offer from us with free dental check-up service by coming to our
            clinic.
          </p>
        </>
      </div>
    </div>
  );
};
