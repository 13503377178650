import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../components';
import { getHome } from '../../firebase';
import { Pages } from '../../helpers';
import moment from 'moment';

export const SessionControl = ({ guestType }) => {
  const { id } = useParams();
  const [loaderActive, setLoaderActive] = useState(true);
  const activeHome = JSON.parse(localStorage.getItem('Home'));
  const sessionDate = moment().format('DD.MM.YYYY hh:mm');

  const setActiveHome = async () => {
    if (activeHome?.id === id) {
      setLoaderActive(false);
      return;
    }
    const data = await getHome(id);
    if (data) {
      localStorage.setItem('Home', JSON.stringify(data));
      setLoaderActive(false);
      return;
    }
    setLoaderActive(false);
  };

  useEffect(() => {
    setActiveHome();
    localStorage.setItem('HomeId', id);
    localStorage.setItem('HomeRoute', guestType === 'current' ? '/' : '/incoming-guest');
    localStorage.setItem('SessionDate', sessionDate);
    localStorage.setItem('activePage', Pages.HOME_SERVICES);
  }, []);

  if (loaderActive) {
    return (
      <div className='main-container'>
        <Loader />;
      </div>
    );
  }

  localStorage.setItem('ValidHome', true);
  localStorage.removeItem('showSessionExpired');
  window.location.href = guestType === 'current' ? '/' : '/incoming-guest';
};
