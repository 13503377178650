import React from 'react';
import ImageSrc from '../../../../../assets/images/massage/massage.png';
export const BaliMassageComp = () => {
  return (
    <div className='massage-info-componet'>
      <div className='massage-info-image-container'>
        <img
          src={ImageSrc}
          alt='massage-info-image'
          className='massage-info-image-container__image'
        />
        <div className='image-footer-info'>
          <div className='image-footer-info__title' style={{ borderRight: '1px solid white' }}>
            €80
          </div>
          <div className='image-footer-info__title'>60 min</div>
        </div>
      </div>
      <div className='massage-info-description'>
        Balinese massage accelerates blood flow throughout the body.
      </div>
      <div className='massage-info-description'>
        The massage applied to specific points opens up blood vessels, rejuvenating the body.
      </div>
      <div className='massage-info-description'>
        When blood circulation is at its normal levels, both the body and mind become much
        healthier.
      </div>
      <div className='massage-info-description'>
        Additionally, this helps reduce pain sensations in the body and promotes faster healing of
        problematic areas.
      </div>
    </div>
  );
};
