import React from 'react';
import { Header, InfoBottom, PriceInfo, Vehicle, Warning } from '../../../../components';
import { MainTitle, Airports, Vehicles } from '../../../../helpers';
import vipIcon from '../../../../assets/images/icons/vip.png';
import taxiIcon from '../../../../assets/images/icons/taxi2.png';

export const SelectVehicle = () => {
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }

  const airport = JSON.parse(localStorage.getItem('Airport'));

  const praceVIP = airport === Airports.SAW.CODE ? Vehicles.VIP.SAW_PRICE : Vehicles.VIP.IST_PRICE;
  const praceTaxi =
    airport === Airports.SAW.CODE ? Vehicles.TAXI.SAW_PRICE : Vehicles.TAXI.IST_PRICE;

  const handleClicked = ({ vehicle }) => {
    localStorage.setItem('Vehicle', JSON.stringify(vehicle));
    window.location.href = '/select-date';
  };

  return (
    <div className='wrapper'>
      <div className='main-title'>{MainTitle.TRANSFER}</div>
      <div className='content-body'>
        <Header title={Airports[airport].CODE} subTitle={Airports[airport].NAME} />
        <div className='request-button-group' style={{ height: 'calc(100% - 160px)' }}>
          <div className='request-button-group__title'>Which one do you prefer?</div>
          <Vehicle
            title={Vehicles.VIP.NAME}
            person={6}
            luggage={6}
            car='Mercedes Vito etc.'
            iconSrc={vipIcon}
            onClick={() => handleClicked({ vehicle: Vehicles.VIP.CODE })}
          />
          <Vehicle
            title={Vehicles.TAXI.NAME}
            person={3}
            luggage={3}
            car='Standart'
            iconSrc={taxiIcon}
            onClick={() => handleClicked({ vehicle: Vehicles.TAXI.CODE })}
          />
        </div>
        <InfoBottom
          title='One way transfer fee'
          contentsComp={
            <>
              <PriceInfo priceUsd={praceVIP} text={Vehicles.VIP.NAME} />
              <PriceInfo priceUsd={praceTaxi} text={Vehicles.TAXI.NAME} />
            </>
          }
        />
      </div>
    </div>
  );
};
