import React from 'react';
import { CloseHeader, ExtraPackage } from './';

export const ServiceExtraPackages = ({ handleClick, headerTitle }) => {
  const packages = [
    {
      title: '1 - Option',
      price: '€20.00',
      description:
        'Tip to Toe 15 min Hot room rest, 10 min Rubbing with a Bath Glove, 35 min Bubble Massage. After the ritual, with Turkish tea, home-made sherbet and Turkish delight',
    },
    {
      title: '2 - Option',
      price: '€40.00',
      description:
        'Cagaloglu Hamam Service 15 min Hot room rest, 10 min Rubbing with a Bath Glove, 20 min Bubble Massage, 15 min Foot massage with linden oil, Turkish tea, Homemade sherbet, Appetizers and Turkish delight',
    },
    {
      title: '3 - Option',
      price: '€100.00',
      description:
        'The Ottoman Luxury Service 15 min Hot room rest, 10 min Rubbing with a Bath Glove, 30 min Bubble Bath, 45 min Aromatherapy Massage',
    },
    {
      title: '4 - Option',
      price: '€140.00',
      description:
        'Sultan Mahmud I 15 min Hot room rest, 45 min Rubbing and Bubble Massage conducted by two attendants, 15 min Foot Massage, Collagen Face-Mask Treatment, 45 min Aromatherapy Massage',
    },
  ];

  return (
    <>
      <div className='main-container'>
        <div className='service-extra-package__content'>
          <CloseHeader title={headerTitle} handleClick={handleClick} />
          <div className='extra-packages-box'>
            {packages.map((item, index) => (
              <>
                <ExtraPackage
                  title={item.title}
                  description={item.description}
                  price={item.price}
                  value={index}
                />
              </>
            ))}
          </div>
          <div className='bottom-info-container'>
            <div className='description' style={{ borderTop: '1px solid #FFFFFF' }}>
              Extra services are for information purposes, you can send your request to the officer
              when you go to the facility.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
