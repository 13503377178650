import React from 'react';

export const InfoBox = ({ info, dateIn, dateOut, btTitle, adress, handleClick }) => {
  return (
    <div className='reservation-component__info'>
      <div className='reservation-component__label'>{info}</div>
      <div className='reservation-component__input'>
        <span className='date'>{dateIn}</span>
        <span className='date'>{dateOut}</span>
        <span className='date'>{adress}</span>
      </div>
      <button onClick={handleClick} className='reservation-component__button'>
        {btTitle}
      </button>
    </div>
  );
};
