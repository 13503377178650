import React from 'react';
import { CloseHeader } from '../../../components';

export const ComponentWrapper = ({ title, comp, handleClick }) => {
  return (
    <>
      <div className='main-title'>Home Info</div>
      <div className='content-body'>
        <CloseHeader title={title} handleClick={() => handleClick()} />
        {comp}
      </div>
    </>
  );
};
