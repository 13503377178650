import React, { useState } from 'react';
import { Header, Warning, AppBox, CloseHeader, NextStepButton } from '../../../../components';
import { MainTitle, chechDevice, DeviceTypes } from '../../../../helpers';
import IstanbulkartIcon from '../../../../assets/images/icons/istanbul-kart.png';
import MoovitIcon from '../../../../assets/images/icons/moovit.png';
import YandexMetroIcon from '../../../../assets/images/icons/yandex-metro.png';
import MuseumPassIcon from '../../../../assets/images/icons/museum-pass.png';
import ObiletIcon from '../../../../assets/images/icons/obilet.png';
import downloadIcon from '../../../../assets/images/icons/download.png';
import {
  IstanbulKartComp,
  MoovitComp,
  YandexMetroComp,
  MuseumPassComp,
  ObiletComp,
} from './components';

export const UsefulApps = () => {
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }

  const UsefulApps = [
    {
      id: 1,
      imageSrc: IstanbulkartIcon,
      title: 'Istanbulkart',
      description: 'A digital card that you can use in all urban transportation.',
      descriptionComp: IstanbulKartComp,
      downloadLink:
        chechDevice() === DeviceTypes.IOS
          ? 'https://apps.apple.com/tr/app/istanbulkart/id1352307391?l=tr'
          : 'https://play.google.com/store/apps/details?id=com.belbim.istanbulkart&hl=tr&gl=US',
    },
    {
      id: 2,
      imageSrc: MoovitIcon,
      title: 'Moovit',
      description: 'Provides real-time public transit information',
      descriptionComp: MoovitComp,
      downloadLink:
        chechDevice() === DeviceTypes.IOS
          ? 'https://apps.apple.com/tr/app/moovit-toplu-ta%C5%9F%C4%B1ma/id498477945?l=tr'
          : 'https://play.google.com/store/apps/details?id=com.tranzmate&hl=tr',
    },
    {
      id: 3,
      imageSrc: YandexMetroIcon,
      title: 'Yandex Metro',
      description:
        'Information about train arrivals and departures, detailed maps of the metro system',
      descriptionComp: YandexMetroComp,
      downloadLink:
        chechDevice() === DeviceTypes.IOS
          ? 'https://apps.apple.com/tr/app/yandex-metro/id392589075?l=tr'
          : 'https://play.google.com/store/apps/details?id=ru.yandex.metro&hl=tr&gl=US',
    },
    {
      id: 4,
      imageSrc: MuseumPassIcon,
      title: 'Museum Pass',
      description:
        'Provides visitors with access to over 300 museums and historical sites across Turkey.',
      descriptionComp: MuseumPassComp,
      downloadLink:
        chechDevice() === DeviceTypes.IOS
          ? 'https://apps.apple.com/tr/app/museums-of-turkey-official/id1511527605'
          : 'https://play.google.com/store/apps/details?id=com.muzekart.app',
    },
    {
      id: 5,
      imageSrc: ObiletIcon,
      title: 'Obilet',
      description: 'Buy e-tickets for transportation in Turkey: buses, trains, and ferries etc.',
      descriptionComp: ObiletComp,
      downloadLink:
        chechDevice() === DeviceTypes.IOS
          ? 'https://apps.apple.com/tr/app/obilet-otel-u%C3%A7ak-otob%C3%BCs-ara%C3%A7/id596039443?l=tr'
          : 'https://play.google.com/store/apps/details?id=com.obilet.androidside&hl=tr&gl=US',
    },
  ];

  const [activeApp, setActiveApp] = useState(false);
  const [activeAppId, setActiveAppId] = useState(1);

  const handleDownloadApp = (downloadLink) => {
    window.open(downloadLink, '_blank');
  };

  const setActiveAppComp = (id) => {
    const app = UsefulApps.find((app) => app.id === id);
    return (
      <>
        <div className='content-body'>
          <CloseHeader title={app.title} handleClick={() => setActiveApp(false)} />
          <app.descriptionComp />
        </div>
        {chechDevice() === DeviceTypes.OTHER ? (
          ''
        ) : (
          <NextStepButton
            text='Download App'
            iconSrc={downloadIcon}
            handleClick={() => handleDownloadApp(app.downloadLink)}
          />
        )}
      </>
    );
  };

  const handleClick = (id) => {
    setActiveAppId(id);
    setActiveApp(true);
  };

  return (
    <div className='wrapper'>
      <div className='main-title'>{MainTitle.USEFUL_INFO}</div>
      {activeApp ? (
        setActiveAppComp(activeAppId)
      ) : (
        <div className='content-body'>
          <Header title='Useful Apps' />
          {UsefulApps.map((app, index) => (
            <AppBox
              key={index}
              imageSrc={app.imageSrc}
              title={app.title}
              description={app.description}
              handleClick={() => handleClick(app.id)}
            />
          ))}
        </div>
      )}
    </div>
  );
};
