import React, { useState } from 'react';
import { Warning, LocationInfo } from '../../../../components';
import { MainTitle, CagalogluInfo } from '../../../../helpers';
import { HamamInfo } from './';

export const Hamam = () => {
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }
  const [activeMap, setActiveMap] = useState(false);

  return (
    <div className='wrapper'>
      <div className='main-title'>{MainTitle.HAMAM}</div>
      {activeMap ? (
        <LocationInfo
          setActiveMap={setActiveMap}
          locationUrl={CagalogluInfo.LOCATION_URL}
          latitude={CagalogluInfo.LATITUDE}
          longitude={CagalogluInfo.LONGITUDE}
        />
      ) : (
        <HamamInfo setActiveMap={setActiveMap} />
      )}
    </div>
  );
};
