import React from 'react';
import { GuidanceButtonGroup } from './';
import { GMap, MapMarker } from 'react-rainbow-components';
import { ExternalLinks } from '../helpers';
import CloseIcon from '../assets/images/icons/close-button.png';

export const LocationInfo = ({ setActiveMap, locationUrl, description, latitude, longitude }) => {
  return (
    <div className='content-body'>
      <div className='map-container'>
        <GMap
          apiKey={ExternalLinks.GOOGLE_MAPS_API_KEY}
          zoom={14}
          latitude={latitude}
          longitude={longitude}>
          <MapMarker
            latitude={latitude}
            longitude={longitude}
            style={{ display: 'none' }}
            className='rainbow-google-map_marker hidden'
            description={description}
          />
        </GMap>
      </div>

      <GuidanceButtonGroup
        iconButtonSrc={CloseIcon}
        title='See on your map'
        handleClickIcon={() => setActiveMap(false)}
        handleClickMap={() => (location.href = locationUrl)}
      />
    </div>
  );
};
