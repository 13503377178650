import React, { useState } from 'react';
import {
  Header,
  NextStepButton,
  SlideComp,
  GuidanceButtonGroup,
  WorkingHours,
  Warning,
} from '../../../../components';
import {
  MainTitle,
  openInNewTab,
  CihangirMassageInfo,
  CihangirMassageWorkingHours,
} from '../../../../helpers';
import InstagramIcon from '../../../../assets/images/icons/instagram-button.png';
import massage1 from '../../../../assets/images/massage/massage1.jpg';
import massage2 from '../../../../assets/images/massage/massage2.jpg';
import massage3 from '../../../../assets/images/massage/massage3.jpg';
import massage4 from '../../../../assets/images/massage/massage4.jpg';

const slideImages = [
  {
    url: massage1,
  },
  {
    url: massage2,
  },
  {
    url: massage3,
  },
  {
    url: massage4,
  },
];

export const MassageInfo = ({ setActiveMap }) => {
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }

  const [hoursPage, setHoursPage] = useState(false);

  return (
    <>
      {hoursPage ? (
        <WorkingHours handleClick={() => setHoursPage(false)} hours={CihangirMassageWorkingHours} />
      ) : (
        <>
          <div className='content-body'>
            <Header
              title='Pasha Spa Wellness'
              subTitle='click for working hours'
              subTitleClass='clickable'
              handleClick={() => setHoursPage(true)}
            />
            <div
              className='description-box'
              style={{
                justifyContent: 'flex-start',
                paddingTop: '5px',
                height: 'calc(100% - 110px)',
              }}>
              <div
                className='description-box__description'
                style={{ padding: '5px', height: '100%', overflow: 'scroll', width: '95%' }}>
                <SlideComp slideImages={slideImages} />
                <div>
                  <div className='description-box__body'>
                    Pasha Spa Wellness offer unique treatments found exclusively at our stunning
                    locations. Experience the bliss of traditional comfort through our relaxing and
                    professional services. Our skilled team values personalized care, working to
                    understand and meet your needs. Whether you are new to massages and skincare or
                    a regular client, our tailored therapies will awaken your senses, promoting
                    inner peace and well-being.
                  </div>
                </div>
              </div>
            </div>
            <GuidanceButtonGroup
              iconButtonSrc={InstagramIcon}
              title='View on map'
              description='İnönü St. , Beyoğlu'
              handleClickIcon={() => openInNewTab(CihangirMassageInfo.INSTAGRAM_URL)}
              handleClickMap={() => setActiveMap(true)}
            />
          </div>
          <NextStepButton routeUrl='/massage-details' mainTitle={MainTitle.MASSAGE} />
        </>
      )}
    </>
  );
};
