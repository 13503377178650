import React from 'react';
import ImageSrc from '../../../../../assets/images/usefulInfo/obilet.png';
export const ObiletComp = () => {
  return (
    <div className='useful-info-componet'>
      <div className='useful-info-image-container'>
        <img
          src={ImageSrc}
          alt='useful-info-image'
          className='useful-info-image-container__image'
        />
      </div>
      <div className='useful-info-description'>
        The obilet app is a mobile application that allows users to purchase electronic tickets for
        various types of transportation in Turkey, such as buses, trains, and ferries.
      </div>
      <div className='useful-info-description'>
        The app also provides real-time information about schedules, routes, and delays, making it
        easier for users to plan their trips.
      </div>
    </div>
  );
};
