export const GuidanceButtonGroup = ({
  iconButtonSrc,
  title = '',
  description = '',
  handleClickIcon,
  handleClickMap,
  styles,
}) => {
  return (
    <div className='guidance-button-group'>
      <div className='guidance-button-group__text-button' onClick={handleClickMap}>
        <div className='guidance-button-group__text-button__title'>{title}</div>
        <div className='guidance-button-group__text-button__description'>{description}</div>
      </div>
      <img
        className='guidance-button-group__icon-button'
        src={iconButtonSrc}
        alt='Button Icon'
        onClick={handleClickIcon}
        style={styles}
      />
    </div>
  );
};
