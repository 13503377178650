import React, { useState } from 'react';
import { Warning, HomeInfoProcedure } from '../../../components';
import { MainTitle, Pages } from '../../../helpers';
import { ComponentWrapper } from '../components';
import wifi from '../../../assets/images/icons/homeInfoIcon/wifi.png';
import rules from '../../../assets/images/icons/homeInfoIcon/rules.png';
import house_location from '../../../assets/images/icons/homeInfoIcon/house_location.png';
import reservation from '../../../assets/images/icons/homeInfoIcon/reservation.png';
import call_host from '../../../assets/images/icons/homeInfoIcon/call_host.png';
import { Wifi } from './wifi';
import { HouseLocation } from './house-location';
import { Reservation } from './reservation';
import { CallHost } from './call-host';
import { Rules } from './rules';

const HomeInfoProcedureList = [
  {
    iconSrc: wifi,
    title: 'Wi-Fi',
    comp: <Wifi />,
  },
  {
    iconSrc: reservation,
    title: 'Reservation',
    comp: <Reservation />,
  },
  {
    iconSrc: house_location,
    title: 'House Location',
    comp: <HouseLocation />,
  },
  {
    iconSrc: call_host,
    title: 'Call Host',
    comp: <CallHost />,
  },
  {
    iconSrc: rules,
    title: 'Rules',
    comp: <Rules />,
  },
];

export const Info = () => {
  const home = JSON.parse(localStorage.getItem('Home'));
  console.log(home);

  if (!home) {
    return <Warning />;
  }

  localStorage.setItem('HomeRoute', '/home-info');
  localStorage.setItem('activePage', Pages.INFO);

  const [activeService, setActiveService] = useState(null);

  const groupProcedures = (arr, groupSize) => {
    if (!home.mapUrl) {
      arr.splice(2, 1);
    }

    if (!home.wifi) {
      arr.splice(0, 1);
    }

    const procedureArr = [];
    for (let i = 0; i < arr.length; i += groupSize) {
      procedureArr.push(arr.slice(i, i + groupSize));
    }
    return procedureArr;
  };

  const groupedProcedures = groupProcedures(HomeInfoProcedureList, 2);

  const handleProcedureClick = (procedure) => {
    setActiveService({
      title: procedure.title,
      comp: procedure.comp,
    });
  };

  return (
    <div className='wrapper'>
      {activeService ? (
        <ComponentWrapper
          title={activeService.title}
          comp={activeService.comp}
          handleClick={setActiveService}
        />
      ) : (
        <>
          <div className='main-title'>{MainTitle.HOME_INFO}</div>
          <div className='content-body border' style={{ justifyContent: 'center' }}>
            <div className='procedure-wrapper'>
              <div className='procedures'>
                {groupedProcedures.map((group, groupIndex) => (
                  <div key={groupIndex} className='procedure-group'>
                    {group.map((procedure, index) => (
                      <HomeInfoProcedure
                        key={index}
                        iconSrc={procedure.iconSrc}
                        title={procedure.title}
                        handleClick={() => handleProcedureClick(procedure)}
                      />
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
