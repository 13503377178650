import React from 'react';
import ImageSrc from '../../../../../assets/images/usefulInfo/moovit.png';
export const MoovitComp = () => {
  return (
    <div className='useful-info-componet'>
      <div className='useful-info-image-container'>
        <img
          src={ImageSrc}
          alt='useful-info-image'
          className='useful-info-image-container__image'
        />
      </div>
      <div className='useful-info-description'>
        Moovit is a free mobile app that provides real-time transit information, route planning, and
        navigation assistance to users for various modes of transportation, such as buses, trains,
        subway/metro, and ferries. It covers more than 3,000 cities across 100+ countries and offers
        real-time information on public transportation schedules and delays.{' '}
      </div>
    </div>
  );
};
