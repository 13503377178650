import { MenuBar, Footer } from '../components';
import { MainContext } from '../store/context';

export const Provider = ({ children }) => {
  return (
    <MainContext.Provider value={{}} className='App'>
      <div className='main-container'>
        <MenuBar />
        {children}
        <Footer />
      </div>
    </MainContext.Provider>
  );
};
