import React from 'react';
import { CloseHeader, Procedure } from '../components';

export const Procedures = ({ handleClick, headerTitle, ProcedureList }) => {
  const groupProcedures = (arr, groupSize) => {
    const procedureArr = [];
    for (let i = 0; i < arr.length; i += groupSize) {
      procedureArr.push(arr.slice(i, i + groupSize));
    }
    return procedureArr;
  };

  const groupedProcedures = groupProcedures(ProcedureList, 2);

  return (
    <div className='content-body'>
      <CloseHeader title={headerTitle} handleClick={handleClick} />
      <div className='procedure-wrapper'>
        <div className='procedures'>
          {groupedProcedures.map((group, groupIndex) => (
            <div key={groupIndex} className='procedure-group'>
              {group.map((procedure, index) => (
                <Procedure
                  key={index}
                  iconSrc={procedure.iconSrc}
                  title={procedure.title}
                  ModalComp={procedure.descriptionModalComp}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// {ProcedureList.map((procedure, index) => (
//   <Procedure
//     key={index}
//     iconSrc={procedure.iconSrc}
//     title={procedure.title}
//     ModalComp={procedure.descriptionModalComp}
//   />
// ))}
