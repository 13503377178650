import React from 'react';

export const Warning = ({ text = 'Apologize, We are unable to offer our services to you.' }) => {
  return (
    <div className='wrapper'>
      <div className='main-container' style={{ justifyContent: 'center' }}>
        <div className='content-body warning'>
          <div className='description'>{text}</div>
          <div className='description'>Please Re-scan the QR code from your home</div>
        </div>
      </div>
    </div>
  );
};
