import React from 'react';
import { Service, Warning } from '../../../components';
import { MainTitle, isSessionExpired, Pages } from '../../../helpers';
import '../../../assets/scss/components.scss';
import '../../../assets/scss/style.scss';
import {
  cleanninIcon,
  lateCheckoutIcon,
  roomSuppliesIcon,
  specialRequestIcon,
  supportIcon,
  marketIcon,
} from '../../../assets/images/footer/home-services/icon';

export const HomeServices = () => {
  const validHome = localStorage.getItem('ValidHome');
  const showSessionExpired = localStorage.getItem('showSessionExpired');

  if (!validHome && !showSessionExpired) {
    return <Warning></Warning>;
  }

  if (showSessionExpired) {
    return <Warning text='Your 48 hours session has ended.'></Warning>;
  }

  const sessionDate = localStorage.getItem('SessionDate');
  const sessionExpired = isSessionExpired(sessionDate);

  if (sessionExpired) {
    localStorage.clear();
    localStorage.setItem('showSessionExpired', true);
    return <Warning text='Your 48 hours session has ended.'></Warning>;
  }

  localStorage.setItem('FormData', JSON.stringify({}));
  localStorage.setItem('HomeRoute', '/');
  localStorage.setItem('activePage', Pages.HOME_SERVICES);

  return (
    <div className='wrapper'>
      <div className='main-title'>{MainTitle.CHOOSE_HOME_SERVICE}</div>
      <div className='content-body border' style={{ justifyContent: 'center' }}>
        <div className='service-group'>
          <Service
            serviceText={'Late Check-Out'}
            serviceIcon={lateCheckoutIcon}
            routeUrl={'/late-checkout'}
          />
          <Service serviceText={'Cleaning'} serviceIcon={cleanninIcon} routeUrl={'/cleaning'} />
        </div>
        <div className='service-group'>
          <Service
            serviceText={'Room Supplies'}
            serviceIcon={roomSuppliesIcon}
            routeUrl={'/room-supplies'}
          />
          <Service
            serviceText={'Special Request'}
            serviceIcon={specialRequestIcon}
            routeUrl={'/special-request'}
          />
        </div>
        <div className='service-group'>
          <Service serviceText={'Support'} serviceIcon={supportIcon} routeUrl={'/support'} />
          <Service serviceText={'Market'} serviceIcon={marketIcon} routeUrl={'/market'} />
        </div>
      </div>
    </div>
  );
};
