import React from 'react';
import { Header, InfoBottom, Warning, Service } from '../../../../components';
import { MainTitle } from '../../../../helpers';
import airportTransfer from '../../../../assets/images/footer/special-services/transportation/airport-transfer.png';
import taxiTransfer from '../../../../assets/images/footer/special-services/transportation.png';

export const SelectTransfer = () => {
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }

  return (
    <div className='wrapper'>
      <div className='main-title'>{MainTitle.TRANSFER}</div>
      <div className='content-body'>
        <Header title='Choose Option' />
        <div className='request-button-group' style={{ height: 'calc(100% - 190px)' }}>
          <div className='request-button-group__title'>Which one do you want?</div>
          <div className='service-group' style={{ width: '240px' }}>
            <Service
              serviceText={'Airport Transfer'}
              serviceIcon={airportTransfer}
              routeUrl={'/select-airport'}
            />
            <Service
              serviceText={'Taxi Transfer'}
              serviceIcon={taxiTransfer}
              routeUrl={'/select-taxi-transfer'}
            />
          </div>
          <InfoBottom
            contentsComp={
              <>
                <div className='vehicle-info-box'>
                  <div className='vehicle-info-box__title'>Airport Transfer:</div>
                  <div className='vehicle-info-box__description'>
                    Quick and Seamless! For a smooth journey to the airport.
                  </div>
                </div>
                <div className='vehicle-info-box'>
                  <div className='vehicle-info-box__title'>Taxi Transfer:</div>
                  <div className='vehicle-info-box__description'>
                    <b>VIP</b> or <b>Yellow Taxi</b>. Freedom to choose your destination.
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};
