import React from 'react';
// import cartIcon from '../assets/images/icons/cart-icon.svg';

export const CartButton = ({ title, handleClick }) => {
  return (
    <div className='cart-button' onClick={handleClick}>
      <div className='cart-button__title'>{title}</div>
      {/* <div className='cart-icon__container'>
        <img src={cartIcon} alt='cart-button__icon' className='cart-icon__container-icon' />
        <div className='cart-icon__container-count'>1</div>
      </div> */}
    </div>
  );
};
