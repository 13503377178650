import React, { useState } from 'react';
import {
  Warning,
  ServiceExtraPackages,
  Header,
  NextStepButton,
  RequestButton,
  Procedures,
  Button,
} from '../../../../components';
import { MainTitle, whatsappClinidentActions } from '../../../../helpers';
import whatsappIcon from '../../../../assets/images/icons/whatsapp.png';
import {
  porcelainIcon,
  hollywoodIcon,
  implantIcon,
  bleachingIcon,
  zirconiumIcon,
} from '../../../../assets/images/footer/special-services/dental-care/icon/icon';

import {
  PorcelainModalComp,
  HollyWoodModalComp,
  ImplantModalComp,
  BleachingModalComp,
  ZirconiumModalComp,
} from './components';

const ClinidentProcedureList = [
  {
    iconSrc: porcelainIcon,
    title: 'Porcelain Laminate',
    descriptionModalComp: <PorcelainModalComp />,
  },
  {
    iconSrc: hollywoodIcon,
    title: 'Hollywood Smile',
    descriptionModalComp: <HollyWoodModalComp />,
  },
  {
    iconSrc: implantIcon,
    title: 'Implant Treatments',
    descriptionModalComp: <ImplantModalComp />,
  },
  {
    iconSrc: bleachingIcon,
    title: 'Teeth Bleaching',
    descriptionModalComp: <BleachingModalComp />,
  },
  {
    iconSrc: zirconiumIcon,
    title: 'Zirconium Crowns',
    descriptionModalComp: <ZirconiumModalComp />,
  },
];

const ButtonStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#2C2C2C',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '12px',
  width: '90%',
  height: '40px',
  color: '#fff',
  fontFamily: 'Monsterrat Bold, sans-serif',
  fontSize: '12px',
  lineHeight: '15px',
  fontWeight: '700',
  marginTop: '5px',
};

export const DentalClinicDetails = () => {
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }
  const [packages, setPackages] = useState(false);
  const [procedures, setProcedures] = useState(false);
  const message =
    "I'm writing from Okeep, I would like to get detailed information about 'Free Dental Checkup'.";

  return (
    <>
      {packages ? (
        <div className='wrapper'>
          <ServiceExtraPackages
            setPackages={setPackages}
            headerTitle='Extra Hamam Packages'
            handleClick={() => setPackages(false)}
          />
        </div>
      ) : (
        <div className='wrapper'>
          <div className='main-title'>{MainTitle.DENTAL}</div>
          {procedures ? (
            <>
              <Procedures
                headerTitle={'Procedure'}
                handleClick={() => setProcedures(false)}
                ProcedureList={ClinidentProcedureList}
              />
            </>
          ) : (
            <>
              <div className='content-body'>
                <Header title='Dental check up' />
                <div className='description-box' style={{ marginTop: '10px' }}>
                  <div className='description-box__description' style={{ padding: '10px' }}>
                    <div>
                      <div className='description-box__body' style={{ marginBottom: '15px' }}>
                        <b className='bold'>Clinident</b> has a special collaboration with{' '}
                        <b className='bold'>Okeep.</b>
                      </div>
                      <div className='description-box__body' style={{ marginBottom: '15px' }}>
                        We provide a special offer to our guests;
                      </div>
                      <div className='description-box__body' style={{ marginBottom: '15px' }}>
                        <b className='bold'>
                          Free dental checkups, consultation and dental x-ray and %20 discount for
                          Okeep guest !
                        </b>
                      </div>
                      <div className='description-box__body' style={{ marginBottom: '15px' }}>
                        We only charge for extra treatments, no extra fee for dental checkup.
                      </div>
                    </div>
                  </div>
                </div>
                <div className='bottom-button-group'>
                  <RequestButton
                    text='Ask Questions 7/24'
                    iconSrc={whatsappIcon}
                    onClick={() => whatsappClinidentActions(message)}
                  />
                  <Button
                    title='Sell all procedure'
                    style={ButtonStyle}
                    handleClick={() => setProcedures(true)}
                  />
                </div>
              </div>
              <NextStepButton routeUrl='/dental-reservation' mainTitle={MainTitle.DENTAL} />
            </>
          )}
        </div>
      )}
    </>
  );
};
