import React from 'react';
import { DateTimePicker, Application } from 'react-rainbow-components';

const theme = {
  rainbow: {
    palette: {
      brand: '#FFC42E',
    },
  },
};

export const DatePicker = ({
  title,
  description = '',
  placeholder = 'Select a date and time',
  style = {},
  handleChange,
  value,
  required,
}) => {
  return (
    <div className='date-picker' style={style}>
      <div className='date-picker__title'>{title}</div>
      <Application theme={theme} className='date-picker__input rainbow-align-content_center'>
        <DateTimePicker
          value={value}
          onChange={handleChange}
          className='custom-date-picker'
          hour24
          minDate={new Date()}
          placeholder={placeholder}
          error={required ? 'This field is required' : false}
        />
      </Application>
      <div className='date-picker__description'>{description}</div>
    </div>
  );
};
