import React from 'react';
import reservationIcon from '../../../../assets/images/reservation/reservation_pic.png';
import { InfoBox } from '../../../../components/InfoBox';

export const Reservation = () => {
  return (
    <div className='reservation-component'>
      <div className='reservation-component__img'>
        <img src={reservationIcon} alt='img'></img>
        {/* <div className='reservation-component__img-title'>
          Cozy Flat 6 min walking to Taksim square
        </div> */}
      </div>

      <div className='info-boxs'>
        <InfoBox
          info='Chek In-Check Out'
          dateIn='12/04/2023-11:00'
          dateOut='16/04/2023-14:00'
          btTitle='Late Check Out'
          handleClick={() => {
            window.location.href = '/late-checkout';
          }}
        />
      </div>

      <div className='reservation-component__id'>Reservation ID: #123456789</div>
    </div>
  );
};
