import React from 'react';
import avatar from '../assets/images/icons/person.png';
// import cancel from '../assets/images/icons/cancel.png';
import driver from '../assets/images/icons/driver.png';
import { Vehicles } from '../helpers/constants';

export const TransferInfo = ({ style }) => {
  const vehicle = JSON.parse(localStorage.getItem('Vehicle'));
  return (
    <div className='transfer-info' style={style}>
      <div className='transfer-info__group'>
        <div className='transfer-info__box'>
          <img className='transfer-info__box-icon' src={avatar} alt='avatar' />
          <div className='transfer-info__box-text'>Max {Vehicles[vehicle].PERSON} Person</div>
        </div>
        {/* <div className='transfer-info__box'>
          <img className='transfer-info__box-icon' src={cancel} alt='cancel' />
          <div className='transfer-info__box-text'>Free cancellation</div>
        </div> */}
      </div>
      <div className='transfer-info__box'>
        <img className='transfer-info__box-icon' src={driver} alt='driver' />
        <div className='transfer-info__box-text'>
          The driver will meet you on the street in front of the home.
        </div>
      </div>
    </div>
  );
};
