import React from 'react';
import { Service, Warning } from '../../../components';
import { MainTitle, isSessionExpired, Pages } from '../../../helpers';
import transportation from '../../../assets/images/footer/special-services/transportation.png';
import dentalCare from '../../../assets/images/footer/special-services/dental-care.png';
import hamam from '../../../assets/images/footer/special-services/hamam.png';
import usefulInformation from '../../../assets/images/footer/special-services/useful-information.png';
import massage from '../../../assets/images/footer/special-services/massage.png';
import '../../../assets/scss/components.scss';
import '../../../assets/scss/style.scss';

export const SpecialServices = () => {
  const validHome = localStorage.getItem('ValidHome');
  const showSessionExpired = localStorage.getItem('showSessionExpired');

  if (!validHome && !showSessionExpired) {
    return <Warning></Warning>;
  }

  if (showSessionExpired) {
    return <Warning text='Your 48 hours session has ended.'></Warning>;
  }

  const sessionDate = localStorage.getItem('SessionDate');
  const sessionExpired = isSessionExpired(sessionDate);

  if (sessionExpired) {
    localStorage.clear();
    localStorage.setItem('showSessionExpired', true);
    return <Warning text='Your 48 hours session has ended.'></Warning>;
  }

  localStorage.setItem('FormData', JSON.stringify({}));
  localStorage.setItem('HomeRoute', '/special-services');
  localStorage.setItem('activePage', Pages.SPECIAL_SERVICES);

  return (
    <div className='wrapper'>
      <div className='main-title'>{MainTitle.CHOOSE_SPECIAL_SERVICE}</div>
      <div className='content-body border' style={{ justifyContent: 'center' }}>
        <div className='service-group'>
          <Service
            serviceText={'Transportation'}
            serviceIcon={transportation}
            routeUrl={'/select-transfer'}
          />
          <Service
            serviceText={'Dental Care'}
            serviceIcon={dentalCare}
            routeUrl={'/dental-clinic'}
          />
        </div>
        <div className='service-group'>
          <Service serviceText={'Hamam'} serviceIcon={hamam} routeUrl={'/hamam'} />
          <Service
            serviceText={'Useful Information'}
            serviceIcon={usefulInformation}
            routeUrl={'/useful-apps'}
          />
        </div>
        <div className='service-group'>
          <Service serviceText={'Massage'} serviceIcon={massage} routeUrl={'/massage'} />
        </div>
      </div>
    </div>
  );
};
