import React, { useState } from 'react';
import {
  Warning,
  ServiceExtraPackages,
  Header,
  NextStepButton,
  CartButton,
} from '../../../../components';
import { MainTitle } from '../../../../helpers';

export const HamamDetails = () => {
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }
  const [packages, setPackages] = useState(false);

  return (
    <>
      {packages ? (
        <div className='wrapper'>
          <ServiceExtraPackages
            setPackages={setPackages}
            headerTitle='Extra Hamam Packages'
            handleClick={() => setPackages(false)}
          />
        </div>
      ) : (
        <div className='wrapper'>
          <div className='main-title'>{MainTitle.HAMAM}</div>
          <>
            <div className='content-body'>
              <Header title='Deals' />
              <div className='description-box' style={{ marginTop: '10px', width: '95%' }}>
                <div className='description-box__description' style={{ padding: '10px' }}>
                  <div>
                    <div
                      className='description-box__title'
                      style={{ marginBottom: '15px', fontSize: '16px' }}>
                      Traditional Turkish Bath Experience (€60.00)
                    </div>
                    <div className='description-box__body' style={{ marginBottom: '15px' }}>
                      The Istanbul Dream - Exfoliating and washing. 15 min Hot room rest, 10 min
                      Exfoliation with a special rubbing mitten and 20 min Bubble Massage. After the
                      ritual, with Turkish tea, home-made sherbet and Turkish delight.
                    </div>
                    <div className='description-box__body'>
                      You must purchase a The Istanbul Dream before booking other Hamam services *
                    </div>
                  </div>
                </div>
              </div>
              <CartButton title='See all extras' handleClick={() => setPackages(true)} />
            </div>
            <NextStepButton routeUrl='/hamam-reservation' mainTitle={MainTitle.HAMAM} />
          </>
        </div>
      )}
    </>
  );
};
