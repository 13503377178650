import React from 'react';

export const OffersCompList = ({ iconSrc, title, discount, handleClick }) => {
  return (
    <div className='content-body-1'>
      <div className='offers-content' onClick={handleClick}>
        <img src={iconSrc} />
        <div className='offers-info'>
          <div className='offers-content__title'>{title}</div>
          <div className='offers-content__discount'>{discount}</div>
        </div>
      </div>
    </div>
  );
};
