import React, { useState } from 'react';
import { Warning, LocationInfo } from '../../../../components';
import { MainTitle, ClinidentInfo } from '../../../../helpers';
import { DentalClinicInfo } from './DentalClinicInfo';

export const DentalClinic = () => {
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }
  const [activeMap, setActiveMap] = useState(false);

  return (
    <div className='wrapper'>
      <div className='main-title'>{MainTitle.DENTAL}</div>
      {activeMap ? (
        <LocationInfo
          setActiveMap={setActiveMap}
          locationUrl={ClinidentInfo.LOCATION_URL}
          latitude={ClinidentInfo.LATITUDE}
          longitude={ClinidentInfo.LONGITUDE}
        />
      ) : (
        <DentalClinicInfo setActiveMap={setActiveMap} />
      )}
    </div>
  );
};
