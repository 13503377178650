import React, { useState, useEffect } from 'react';

export const SuppliesCompList = ({ iconSrc, price, product, info }) => {
  const suppliesList = JSON.parse(localStorage.getItem('supplies')) || {};
  const [count, setCount] = useState(suppliesList[product] || 0);

  useEffect(() => {
    if (count > 0) {
      suppliesList[product] = count;
    } else {
      delete suppliesList[product];
    }
  }, [count]);

  useEffect(() => {
    localStorage.setItem('supplies', JSON.stringify(suppliesList));
  }, [suppliesList]);

  return (
    <>
      <div className='suplies-products-content'>
        <div className='suplies-products-content__img-container'>
          <img src={iconSrc} alt='img'></img>
          <div className='suplies-products-content__img-title'>
            <h2>{price}</h2>
          </div>
        </div>
        <div className='suplies-products-content__product'>
          <div className='suplies-products-content__header'>{product}</div>
          <div className='suplies-products-content__info'>
            <span>{info}</span>
          </div>
        </div>
        <div className='suplies-products-content__product-piece'>
          <div className='plus' onClick={() => setCount(count + 1)}>
            +
          </div>
          <div className='counter'>{count}</div>
          <div className='minus' onClick={() => count > 0 && setCount(count - 1)}>
            -
          </div>
        </div>
      </div>
    </>
  );
};
