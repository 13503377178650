import React, { useState, useEffect } from 'react';
import {
  Header,
  DatePicker,
  InfoBottom,
  PriceInfo,
  NextStepButton,
  Warning,
} from '../../../../components';
import { MainTitle } from '../../../../helpers';

export const Cleaning = () => {
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }

  const [formData, setFormData] = useState(JSON.parse(localStorage.getItem('FormData')) || {});
  const [date, setDate] = useState(formData.date || false);
  const [dateError, setDateError] = useState(false);

  const handleDateChange = (value) => {
    setDate(value);
    setFormData({
      ...formData,
      date: value,
    });
    if (value) {
      setDateError(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.date) {
      setDateError(true);
      return;
    }

    localStorage.setItem('MainTitle', MainTitle.CLEANING);
    window.location.href = '/request';
  };

  useEffect(() => {
    localStorage.setItem('FormData', JSON.stringify(formData));
  }, [formData]);

  return (
    <div className='wrapper'>
      <div className='main-title'>{MainTitle.CLEANING}</div>
      <div className='content-body'>
        <Header title='Reservation' />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: 'calc(100% - 120px)',
          }}>
          <DatePicker
            title='When do you want ?'
            description='You must send us your cleaning request 1 day before.'
            value={date}
            handleChange={handleDateChange}
            required={dateError}
          />
          <div className='info-box' style={{ margin: '40px 0 5px 0', width: '92%' }}>
            <div
              className='info-box__description'
              style={{ fontWeight: '600', fontFamily: 'Monsterrat SemiBold' }}>
              With the cleaning services that provide good service in the field, we clean your house
              like the first day in{' '}
              <span style={{ fontWeight: '700', fontFamily: 'Monsterrat Bold' }}>3-4 hours.</span>
            </div>
          </div>

          <InfoBottom
            title='Fixed Price'
            contentsComp={
              <>
                <PriceInfo priceUsd={30} text='Home Cleaning' />
              </>
            }
          />
        </div>
      </div>
      <NextStepButton handleClick={handleSubmit} />
    </div>
  );
};
