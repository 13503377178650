import React, { useState, useEffect } from 'react';
import { MainTitle } from '../../../../helpers';
import {
  Header,
  NextStepButton,
  Warning,
  DatePicker,
  InfoBottom,
  PriceInfo,
  InfoText,
} from '../../../../components';

export const LateCheckout = () => {
  const home = JSON.parse(localStorage.getItem('Home'));
  if (!home) {
    return <Warning></Warning>;
  }

  const [formData, setFormData] = useState(JSON.parse(localStorage.getItem('FormData')) || {});
  const [date, setDate] = useState(formData.date || false);
  const [dateError, setDateError] = useState(false);

  const handleDateChange = (value) => {
    setDate(value);
    setFormData({
      ...formData,
      date: value,
    });
    if (value) {
      setDateError(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.date) {
      if (!formData.date) {
        setDateError(true);
      }
      return;
    }

    localStorage.setItem('MainTitle', MainTitle.LATE_CHECKOUT);
    window.location.href = '/request';
  };

  useEffect(() => {
    localStorage.setItem('FormData', JSON.stringify(formData));
  }, [formData]);

  return (
    <div className='wrapper'>
      <div className='main-title'>{MainTitle.LATE_CHECKOUT}</div>
      <div className='content-body'>
        <Header title='Reservation' />
        <div className='late-check-out-container'>
          <DatePicker
            title='When do you want to check out ?'
            description='You must send us your cleaning request 1 day before.'
            value={date}
            handleChange={handleDateChange}
            required={dateError}
          />

          <InfoText
            description={`"${'Once we receive your request for a late check-out, we will get back to you based on availability before confirming it.'}"`}
          />

          <InfoBottom
            title='Hourly Price'
            contentsComp={
              <>
                <PriceInfo priceUsd={10} text='Late Check Out' />
              </>
            }
          />
        </div>
      </div>

      <NextStepButton handleClick={handleSubmit} />
    </div>
  );
};
