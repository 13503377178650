import React from 'react';
import wifi from '../../../../assets/images/icons/homeInfoIcon/wifi.png';
import { TextInput } from '../../../../components/TextInput';

export const Wifi = () => {
  const home = JSON.parse(localStorage.getItem('Home'));
  const username = home.wifi.name || 'OkeepHome';
  const password = home.wifi.password || 'OkeepPassword';

  return (
    <div className='wifi-component'>
      <div className='wifi-component__icon'>
        <img src={wifi}></img>
      </div>
      <div className='wifi-component__title'>Wi-Fi</div>

      <TextInput
        label='User Name'
        value={username}
        disabled
        className='wifi-component__input'
        style={{ height: 'unset' }}
      />
      <TextInput
        label='Password'
        value={password}
        disabled
        className='wifi-component__input'
        style={{ height: 'unset' }}
      />
    </div>
  );
};
