import React from 'react';
import ImageSrc from '../../../../../assets/images/usefulInfo/yandex-metro.png';
export const YandexMetroComp = () => {
  return (
    <div className='useful-info-componet'>
      <div className='useful-info-image-container'>
        <img
          src={ImageSrc}
          alt='useful-info-image'
          className='useful-info-image-container__image'
        />
      </div>
      <div className='useful-info-description'>
        The app offers real-time information about train arrivals and departures, detailed maps of
        the metro system, and information about the location of stations, entrances, and exits.
      </div>
      <div className='useful-info-description'>
        Users can also plan their routes and get information about the estimated travel time, fare
        cost, and any possible delays or disruptions.
      </div>
    </div>
  );
};
