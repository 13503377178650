import React from 'react';
import ImageSrc from '../../../../../assets/images/massage/massage.png';
export const SwedishMassageComp = () => {
  return (
    <div className='massage-info-componet'>
      <div className='massage-info-image-container'>
        <img
          src={ImageSrc}
          alt='massage-info-image'
          className='massage-info-image-container__image'
        />
        <div className='image-footer-info'>
          <div className='image-footer-info__title' style={{ borderRight: '1px solid white' }}>
            €60
          </div>
          <div className='image-footer-info__title'>50 min</div>
        </div>
      </div>
      <div className='massage-info-description'>
        Swedish massage is one of the most popular forms of massage therapy.
      </div>
      <div className='massage-info-description'>
        It can help you relax, give you more energy, and may help after an injury.
      </div>
      <div className='massage-info-description'>
        Swedish massage involves long, kneading strokes combined with rhythmic tapping strokes and
        movement of the joints.
      </div>
      <div className='massage-info-description'>
        This type of massage targets the uppermost layer of muscles and aims to relieve muscle
        tension.
      </div>
    </div>
  );
};
