import React from 'react';
import { getTimeString, getDateString } from '../helpers';

export const DentalRequest = ({ setMessage }) => {
  const home = JSON.parse(localStorage.getItem('Home'));
  const formData = JSON.parse(localStorage.getItem('FormData'));
  const timeString = getTimeString(formData.date);
  const dateString = getDateString(formData.date);

  const from = `From: ${home.title}`;
  const request = 'Request: Dental Care';
  const name = `Name: ${formData.name}`;
  const time = `Time: ${timeString} / ${dateString}`;
  const extraTransfer = `Extra Transfer: ${formData.checkedTransferService ? 'Yes' : 'No'}`;
  const phone = `Phone: ${formData.phone}`;
  const address = `Address: ${home.address}`;
  const message = `${from}\n${request}\n${name}\n${time}\n${extraTransfer}\n${phone}\n${address}`;

  setMessage(encodeURIComponent(message));

  return (
    <div className='request-info'>
      <div className='request-info__text'>Hello Okeep,</div>
      <div className='request-info__text'>
        I{"'"}m <span>{formData.name}</span>
      </div>
      <div className='request-info__text'>
        I request a <span>Free Dental Check Up</span>
      </div>
      {formData.checkedTransferService && (
        <div className='request-info__text'>
          Also <span>I want Transfer Service</span>
        </div>
      )}
      <div className='request-info__text'>
        I will go to <span>{timeString}</span> on <span>{dateString}</span>
      </div>
      <div className='request-info__text'>
        My phone number is: <span>{formData.phone}</span>
      </div>
    </div>
  );
};
