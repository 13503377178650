import React, { useState, useEffect } from 'react';
import {
  Header,
  Warning,
  NextStepButton,
  DatePicker,
  TextInput,
  CounterInputGroup,
  CheckboxToggleInput,
} from '../../../../components';
import { MainTitle } from '../../../../helpers';

export const HamamReservation = () => {
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }

  const [formData, setFormData] = useState(JSON.parse(localStorage.getItem('FormData')) || {});
  const [name, setName] = useState(formData.name || '');
  const [date, setDate] = useState(formData.date || false);
  const [checkedTransferService, setCheckedTransferService] = useState(
    formData.checkedTransferService || false,
  );
  const [counterMale, setCounterMale] = useState(formData.male || 0);
  const [counterFemale, setCounterFemale] = useState(formData.female || 0);
  const [nameError, setNameError] = useState(false);
  const [dateError, setDateError] = useState(false);

  const handleToggleChange = (e) => {
    setCheckedTransferService(e.target.checked);
    setFormData({ ...formData, checkedTransferService: e.target.checked });
  };

  const handleNameChange = (e) => {
    setName(e.target.value.replace(/(?:^|\s|["'([{])+\S/g, (l) => l.toUpperCase()));
    setFormData({
      ...formData,
      name: e.target.value.replace(/(?:^|\s|["'([{])+\S/g, (l) => l.toUpperCase()),
    });
    if (e.target.value) {
      setNameError(false);
    }
  };

  const handleMaleChange = (e) => {
    setCounterMale(e);
    setFormData({ ...formData, male: e });
  };

  const handleFemaleChange = (e) => {
    setCounterFemale(e);
    setFormData({ ...formData, female: e });
  };

  const handleDateChange = (value) => {
    setDate(value);
    setFormData({
      ...formData,
      date: value,
    });
    if (value) {
      setDateError(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.name || !formData.date) {
      if (!formData.name) {
        setNameError(true);
      }
      if (!formData.date) {
        setDateError(true);
      }
      return;
    }

    localStorage.setItem('MainTitle', MainTitle.HAMAM);
    window.location.href = '/request';
  };

  useEffect(() => {
    localStorage.setItem('FormData', JSON.stringify(formData));
  }, [formData]);

  return (
    <div className='wrapper'>
      <div className='main-title'>{MainTitle.HAMAM}</div>
      <div className='content-body'>
        <Header title='Reservation' />
        <div className='request-button-group' style={{ height: 'calc(100% - 150px)' }}>
          <DatePicker
            title='Cağaloğlu Hamamı'
            placeholder='When do you want ?'
            style={{ padding: '5px' }}
            value={date}
            handleChange={handleDateChange}
            required={dateError}
          />
          <TextInput
            placeholder='Name & Surname'
            handleChange={handleNameChange}
            value={name}
            required={nameError}
          />
          <CounterInputGroup
            title='How many people?'
            counter1={counterFemale}
            counter2={counterMale}
            setCounter1={handleFemaleChange}
            setCounter2={handleMaleChange}
          />
        </div>

        <div className='bottom-info-container'>
          <CheckboxToggleInput
            title='Do you want transfer service?'
            value={checkedTransferService}
            onChange={handleToggleChange}
          />

          <div className='description'>
            One way transfer fee just 5 USD. The transfer is free if you buy any service.
          </div>
        </div>
      </div>
      <NextStepButton handleClick={handleSubmit} />
    </div>
  );
};
