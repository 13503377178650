import React from 'react';
import { GuidanceButtonGroup, TextInput } from '../components';
import InstagramIcon from '../assets/images/icons/instagram-button.png';
import { openInNewTab } from '../helpers';

export const DiscountInfo = ({ setActiveMap, offerInfo }) => {
  return (
    <>
      <div
        className='description-box'
        style={{
          justifyContent: 'flex-start',
          paddingTop: '5px',
          height: 'calc(100% - 125px)',
        }}>
        <div
          className='description-box__description'
          style={{ padding: '5px', height: '100%', overflow: 'scroll', width: '95%' }}>
          <div className='breakfast-info-container'>
            <div className='breakfast-info__image'>
              <img src={offerInfo.IMAGE} alt='massage' />
            </div>
            <div className='breakfast-info__text'>
              <div className='breakfast-info__text__title'>{offerInfo.DESCRIPTION}</div>
              <div className='breakfast-info__text__discount'>
                {offerInfo.DISCOUNT} for Okeep guest !
              </div>
              <div className='discount-code'>
                <div className='discount-code__label'>Your discount code</div>
                <TextInput value={'OKEEPANDMORE'} disabled={true} style={{ height: '30px' }} />
              </div>
              <div className='code-share'>Just save or screenshot your code</div>
            </div>
          </div>
        </div>
      </div>
      <GuidanceButtonGroup
        iconButtonSrc={InstagramIcon}
        title='View on map'
        handleClickIcon={() => openInNewTab(offerInfo.INSTAGRAM_URL)}
        handleClickMap={() => setActiveMap(true)}
      />
    </>
  );
};
