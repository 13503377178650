import React from 'react';
import back from '../assets/images/icons/back.png';
import home from '../assets/images/icons/home2.png';

export const Header = ({
  title,
  subTitle,
  subTitleClass = 'header-body__subtitle',
  handleClick = () => {},
}) => {
  const homeRoute = localStorage.getItem('HomeRoute');
  return (
    <>
      <div className='header-component'>
        <div className='back-button' onClick={() => window.history.back()}>
          <img src={back}></img>
        </div>
        <div className='header-body'>
          <div className='header-body__title'>{title}</div>
          <div className={subTitleClass} onClick={handleClick}>
            {subTitle}
          </div>
        </div>
        <div className='home-button' onClick={() => (window.location.href = homeRoute)}>
          <img src={home}></img>
        </div>
      </div>
    </>
  );
};
