import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

export const SlideComp = ({ slideImages }) => {
  const slideRef = React.createRef();
  const properties = {
    duration: 4000,
    autoplay: slideImages.length > 1,
    transitionDuration: 500,
    arrows: false,
    infinite: slideImages.length > 1,
    easing: 'ease',
  };

  if (!slideImages) {
    return null;
  }

  return (
    <div className='slide-container'>
      <Slide ref={slideRef} {...properties}>
        {slideImages.map((each, index) => (
          <div key={index} className='each-slide'>
            <img className='lazy' src={each.url} alt></img>
          </div>
        ))}
      </Slide>
    </div>
  );
};
