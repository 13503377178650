import { OkeepInfo, ClinidentInfo, DeviceTypes } from './constants';
import { sendTelegramMessage } from '../service';
import moment from 'moment';

export const openInNewTab = (url) => {
  window.location.href = url;
};

export const whatsappActions = (message) => {
  openInNewTab(OkeepInfo.WHATSAPP_API_URL + message);
};

export const whatsappClinidentActions = (message) => {
  openInNewTab(ClinidentInfo.WHATSAPP_API_URL + message);
};

export const telegramActions = (message) => {
  sendTelegramMessage(message);
  openInNewTab(OkeepInfo.TELEGRAM_API_URL);
};

export const isSessionExpired = (sessionDate) => {
  const currentDate = moment().format('DD.MM.YYYY hh:mm');
  const date1 = moment(sessionDate, 'DD.MM.YYYY hh:mm');
  const date2 = moment(currentDate, 'DD.MM.YYYY hh:mm');
  const diff = date2.diff(date1, 'hours');
  if (diff < 48) {
    return false;
  }
  return true;
};

export const chechDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.indexOf('android') !== -1) {
    return DeviceTypes.ANDROID;
  } else if (
    userAgent.indexOf('iphone') !== -1 ||
    userAgent.indexOf('ipad') !== -1 ||
    userAgent.indexOf('ipod') !== -1
  ) {
    return DeviceTypes.IOS;
  } else {
    return DeviceTypes.OTHER;
  }
};

export const getTimeString = (date) => {
  return new Date(date).toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const getDateString = (date) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(date).toLocaleDateString('en-us', options);
};
