import React from 'react';
import { getTimeString, getDateString, Airports } from '../helpers';

export const IncomingTransferRequest = ({ setMessage }) => {
  const home = JSON.parse(localStorage.getItem('Home'));
  const formData = JSON.parse(localStorage.getItem('FormData'));
  const timeString = getTimeString(formData.date);
  const dateString = getDateString(formData.date);
  const airport = JSON.parse(localStorage.getItem('Airport'));

  const from = `From: ${home.title}`;
  const request = 'Request: Incoming Transportation';
  const name = `Name: ${formData.name}`;
  const location = `Airport: ${Airports[airport].NAME}`;
  const arriveTime = `Arrive Time: ${timeString} / ${dateString}`;
  const flightNumber = `Flight Number: ${formData.flightNumber}`;
  const address = `Address: ${home.address}`;
  const message = `${from}\n${request}\n${name}\n${location}\n${arriveTime}\n${flightNumber}\n${address}`;

  setMessage(encodeURIComponent(message));

  return (
    <div className='request-info'>
      <div className='request-info__text'>Hello Okeep,</div>
      <div className='request-info__text'>
        I{"'"}m <span>{formData.name}</span>
      </div>
      <div className='request-info__text'>
        I request a transfer service from <span>{Airports[airport].NAME}</span>
      </div>
      <div className='request-info__text'>
        I’ll arrive at <span>{timeString}</span> on <span>{dateString}</span>
      </div>
      <div className='request-info__text'>
        My flight number is{' '}
        <span>
          {"' "}
          {formData.flightNumber}
          {" '"}
        </span>
      </div>
    </div>
  );
};
