import React from 'react';

export const RoomSuppliesRequest = ({ setMessage }) => {
  const home = JSON.parse(localStorage.getItem('Home'));
  const supliesList = JSON.parse(localStorage.getItem('supplies')) || {};
  const supliesListMessage = Object.keys(supliesList)
    .map((key) => {
      return `${key}: ${supliesList[key]}`;
    })
    .join(',\n');

  const from = `From: ${home.title}`;
  const request = 'Request: Room Supplies';
  const address = `Address: ${home.address}`;
  const supplies = `Message: ${supliesListMessage}`;
  const message = `${from}\n${request}\n${supplies}\n${address}`;

  setMessage(encodeURIComponent(message));

  return (
    <div className='request-info'>
      <div className='request-info__text'>Hello Okeep,</div>
      <div className='request-info__text'>I would like to request</div>
      <div className='request-info__text'>
        <span>{supliesListMessage}</span>
      </div>
    </div>
  );
};
