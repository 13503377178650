import React from 'react';
import { porcelainImg } from '../../../../../assets/images/footer/special-services/dental-care/image/img';

export const PorcelainModalComp = () => {
  return (
    <div className='modal-comp__body'>
      <div className='modal-comp__body-img'>
        <img src={porcelainImg} alt='porcelain' />
      </div>
      <div className='modal-comp__body-text'>
        <>
          <p>
            We cover them in the most appropriate way to their original form without harming your
            teeth.
          </p>
          <p>Our treatments are guaranteed for 10 years. We provide warranty certificate.</p>
          <p>
            You can get a price offer from us with free dental check-up service by coming to our
            clinic.
          </p>
        </>
      </div>
    </div>
  );
};
