import React from 'react';

export const PriceInfo = ({ priceUsd, text }) => {
  return (
    <div className='price-info'>
      <div className='price-info__text'>{text}</div>
      <div className='price-box'>
        <div className='price-box__usd'>{priceUsd} USD</div>
      </div>
    </div>
  );
};
