import React, { useState } from 'react';
import {
  Header,
  NextStepButton,
  SlideComp,
  GuidanceButtonGroup,
  WorkingHours,
  Warning,
} from '../../../../components';
import { MainTitle, openInNewTab, CagalogluInfo, CagalogluWorkingHours } from '../../../../helpers';
import InstagramIcon from '../../../../assets/images/icons/instagram-button.png';
import hamam1 from '../../../../assets/images/hamam/hamam1.jpg';
import hamam2 from '../../../../assets/images/hamam/hamam2.jpg';
import hamam3 from '../../../../assets/images/hamam/hamam3.jpg';

const slideImages = [
  {
    url: hamam1,
  },
  {
    url: hamam2,
  },
  {
    url: hamam3,
  },
];

export const HamamInfo = ({ setActiveMap }) => {
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }

  const [hoursPage, setHoursPage] = useState(false);

  return (
    <>
      {hoursPage ? (
        <WorkingHours handleClick={() => setHoursPage(false)} hours={CagalogluWorkingHours} />
      ) : (
        <>
          <div className='content-body'>
            <Header
              title='Cağaloğlu Hamam'
              subTitle='click for working hours'
              subTitleClass='clickable'
              handleClick={() => setHoursPage(true)}
            />
            <div
              className='description-box'
              style={{
                justifyContent: 'flex-start',
                paddingTop: '5px',
                height: 'calc(100% - 110px)',
              }}>
              <div
                className='description-box__description'
                style={{ padding: '5px', height: '100%', overflow: 'scroll', width: '95%' }}>
                <SlideComp slideImages={slideImages} />
                <div>
                  <div className='description-box__header'>
                    Visit an authentic Cagaloglu Hamam in Istanbul’s old city, dating back to
                    Ottoman times.
                  </div>
                  <div className='description-box__body'>
                    Experience a bathing tradition that is still popular today. Cağaloğlu is the
                    last big hamam built in the Ottoman era and was put in the list of 1000 must-see
                    places before you die by New York Times! The Hamam, has been in continuous
                    operation since it opened in 1741.
                  </div>
                </div>
              </div>
            </div>
            <GuidanceButtonGroup
              iconButtonSrc={InstagramIcon}
              title='View on map'
              description='Prof. K. İ. Gürkan St. , Fatih'
              handleClickIcon={() => openInNewTab(CagalogluInfo.INSTAGRAM_URL)}
              handleClickMap={() => setActiveMap(true)}
            />
          </div>
          <NextStepButton routeUrl='/hamam-details' mainTitle={MainTitle.HAMAM} />
        </>
      )}
    </>
  );
};
