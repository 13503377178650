export const WorkingHoursBox = ({ hours }) => {
  return (
    <>
      <div className='working-hours-box'>
        <div className='working-hours-box__description'>
          <div className='working-hours-box__description__day'>
            <div className='working-hours-box__description__day__title'>Monday</div>
            <div className='working-hours-box__description__day__time'>{hours.WEEKDAYS}</div>
          </div>
          <div className='working-hours-box__divider'></div>
          <div className='working-hours-box__description__day'>
            <div className='working-hours-box__description__day__title'>Tuesday</div>
            <div className='working-hours-box__description__day__time'>{hours.WEEKDAYS}</div>
          </div>
          <div className='working-hours-box__divider'></div>
          <div className='working-hours-box__description__day'>
            <div className='working-hours-box__description__day__title'>Wednesday</div>
            <div className='working-hours-box__description__day__time'>{hours.WEEKDAYS}</div>
          </div>
          <div className='working-hours-box__divider'></div>
          <div className='working-hours-box__description__day'>
            <div className='working-hours-box__description__day__title'>Thursday</div>
            <div className='working-hours-box__description__day__time'>{hours.WEEKDAYS}</div>
          </div>
          <div className='working-hours-box__divider'></div>
          <div className='working-hours-box__description__day'>
            <div className='working-hours-box__description__day__title'>Friday</div>
            <div className='working-hours-box__description__day__time'>{hours.WEEKDAYS}</div>
          </div>
          <div className='working-hours-box__divider'></div>
          <div className='working-hours-box__description__day'>
            <div className='working-hours-box__description__day__title'>Saturday</div>
            <div className='working-hours-box__description__day__time'>{hours.SATURDAY}</div>
          </div>
          <div className='working-hours-box__divider'></div>
          <div className='working-hours-box__description__day'>
            <div className='working-hours-box__description__day__title'>Sunday</div>
            <div className='working-hours-box__description__day__time'>{hours.SUNDAY}</div>
          </div>
        </div>
      </div>
    </>
  );
};
