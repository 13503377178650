import React, { useState } from 'react';
import { Header, InfoText, RequestButton, Warning } from '../../../../components';
import telegramIcon from '../../../../assets/images/icons/telegram.png';
import whatsappIcon from '../../../../assets/images/icons/whatsapp.png';
import { whatsappActions, telegramActions, MainTitle } from '../../../../helpers';
import { Textarea } from 'react-rainbow-components';

export const Market = () => {
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }

  const [clientMessage, setClientMessage] = useState('');

  const from = `From: ${home ? home.title : ''}`;
  const request = 'Request: Market';
  const address = `Address: ${home.address}`;
  const help = `Message : ${clientMessage}`;
  const message = `${from}\n${request}\n${address}\n${help}`;

  return (
    <div className='wrapper'>
      <div className='main-title'>{MainTitle.MARKET}</div>
      <div className='content-body'>
        <Header title='Market' />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: 'calc(100% - 190px)',
          }}>
          <div className='info-text-group' style={{ margin: '10px 0' }}>
            <InfoText description='Please let us know of anything from market' />
          </div>
          <div className='text-area'>
            <Textarea
              id='example-textarea-1'
              rows={2}
              placeholder='You can write whatever you want to say here...'
              className='rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto custom-text-area'
              value={clientMessage}
              onChange={(e) => setClientMessage(e.target.value)}
            />
          </div>
        </div>

        <div className='request-button-group' style={{ position: 'absolute', bottom: '10px' }}>
          <div className='request-button-group__title'>Send With</div>
          <RequestButton
            text='Whatsapp'
            iconSrc={whatsappIcon}
            onClick={() => whatsappActions(encodeURIComponent(message))}
          />
          <RequestButton
            text='Telegram'
            iconSrc={telegramIcon}
            onClick={() => telegramActions(encodeURIComponent(message))}
            className='hidden'
          />
        </div>
      </div>
    </div>
  );
};
