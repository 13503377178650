import React from 'react';
import diamond from '../assets/images/icons/homeInfoIcon/diamond.png';
import services from '../assets/images/icons/homeInfoIcon/services.png';
import offers from '../assets/images/icons/homeInfoIcon/offers.png';
import { Pages } from '../helpers';

export const Footer = () => {
  const activePage = localStorage.getItem('activePage');

  const handleClickSpecialServices = () => {
    localStorage.setItem('HomeRoute', '/special-services');
    localStorage.setItem('activePage', Pages.SPECIAL_SERVICES);
    window.location.href = '/special-services';
  };

  const handleClickHomeServices = () => {
    localStorage.setItem('HomeRoute', '/');
    localStorage.setItem('activePage', Pages.HOME_SERVICES);
    window.location.href = '/';
  };

  const hanleClickOffers = () => {
    localStorage.setItem('HomeRoute', '/offers');
    localStorage.setItem('activePage', Pages.OFFERS);
    window.location.href = '/offers';
  };

  return (
    <div className='footer-component'>
      <div
        className={`footer footer__border ${activePage === Pages.SPECIAL_SERVICES && 'active'}`}
        onClick={handleClickSpecialServices}>
        <div className='footer__logo'>
          <img src={diamond} alt='diamond' />
        </div>
        <div className='footer__title'>Special Services</div>
      </div>
      <div
        className={`footer footer__border ${activePage === Pages.HOME_SERVICES && 'active'}`}
        onClick={handleClickHomeServices}>
        <div className='footer__logo'>
          <img src={services} alt='services' />
        </div>
        <div className='footer__title'>Home Services</div>
      </div>
      <div
        className={`footer ${activePage === Pages.OFFERS && 'active'}`}
        onClick={hanleClickOffers}>
        <div className='footer__logo'>
          <img src={offers} alt='offers' />
        </div>
        <div className='footer__title'>Offers</div>
      </div>
    </div>
  );
};
