import React from 'react';
import { Header, InfoBottom, Vehicle, Warning } from '../../../../components';
import { MainTitle, Vehicles } from '../../../../helpers';
import vipIcon from '../../../../assets/images/icons/vip.png';
import taxiIcon from '../../../../assets/images/icons/taxi2.png';

export const SelectTaxiTransfer = () => {
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }

  const handleClicked = ({ vehicle }) => {
    localStorage.setItem('Vehicle', JSON.stringify(vehicle));
    window.location.href = '/taxi-reservation';
  };

  return (
    <div className='wrapper'>
      <div className='main-title'>{MainTitle.TAXI_TRANSFER}</div>
      <div className='content-body'>
        <Header title='Taxi Transfer' />
        <div className='request-button-group' style={{ height: 'calc(100% - 130px)' }}>
          <div className='request-button-group__title'>Which one do you prefer?</div>
          <Vehicle
            title={Vehicles.VIP.NAME}
            person={6}
            luggage={6}
            car='Mercedes Vito etc.'
            iconSrc={vipIcon}
            onClick={() => handleClicked({ vehicle: Vehicles.VIP.CODE })}
          />
          <Vehicle
            title={Vehicles.TAXI.NAME}
            person={3}
            luggage={3}
            car='Standart'
            iconSrc={taxiIcon}
            onClick={() => handleClicked({ vehicle: Vehicles.TAXI.CODE })}
          />
        </div>
        <InfoBottom
          title='Information'
          contentsComp={
            <div className='vehicle-info-box'>
              <div className='vehicle-info-box__description'>
                Exact price and time information will be given after the request is submitted.
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};
