import React, { useState, createElement } from 'react';
import { Header, RequestButton, Warning } from '../../components';
import {
  TransferRequest,
  IncomingTransferRequest,
  HomeCleaningRequest,
  DentalRequest,
  HamamRequest,
  MassageRequest,
  LateCheckoutRequest,
  RoomSuppliesRequest,
  TaxiTransferRequest,
} from '../../requestMessageComps';
import { whatsappActions, telegramActions, MainTitle } from '../../helpers';
import telegramIcon from '../../assets/images/icons/telegram.png';
import whatsappIcon from '../../assets/images/icons/whatsapp.png';

const serviceRequests = {
  TRANSFER: TransferRequest,
  INCOMING_TRANSFER: IncomingTransferRequest,
  CLEANING: HomeCleaningRequest,
  DENTAL: DentalRequest,
  HAMAM: HamamRequest,
  MASSAGE: MassageRequest,
  LATE_CHECKOUT: LateCheckoutRequest,
  ROOM_SUPPLIES: RoomSuppliesRequest,
  TAXI_TRANSFER: TaxiTransferRequest,
};

export const Request = () => {
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }

  const [message, setMessage] = useState('');
  const mainTitle = localStorage.getItem('MainTitle');
  const activeTitleKey = Object.keys(MainTitle).find((key) => MainTitle[key] === mainTitle);

  return (
    <div className='wrapper'>
      <div className='main-title'>{mainTitle}</div>
      <div className='content-body'>
        <Header title='Complete' subTitle='Your Request' />
        {createElement(serviceRequests[activeTitleKey], { setMessage })}
        <div className='request-button-group' style={{ position: 'absolute', bottom: '10px' }}>
          <div className='request-button-group__title'>Send Request With</div>
          <RequestButton
            text='Whatsapp'
            iconSrc={whatsappIcon}
            onClick={() => whatsappActions(message)}
          />
          <RequestButton
            text='Telegram'
            iconSrc={telegramIcon}
            onClick={() => telegramActions(message)}
            className='hidden'
          />
        </div>
      </div>
    </div>
  );
};
