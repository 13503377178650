import React from 'react';
import carIcon from '../assets/images/icons/car.png';
import avatar from '../assets/images/icons/person.png';
import luggageIcon from '../assets/images/icons/luggage.png';

export const Vehicle = ({ title, person, luggage, car, iconSrc, onClick }) => {
  return (
    <div className='vehicle' onClick={onClick}>
      <div className='vehicle__icon'>
        <img src={iconSrc} alt='' />
      </div>
      <div className='vehicle__info'>
        <div className='vehicle__info__title'>{title}</div>
        <div className='vehicle__info__group'>
          <div className='vehicle__info__icon__text'>
            <img className='vehicle__info__icon' src={avatar} alt='avatar' />
            <div className='vehicle__info__text'>{person} Person</div>
          </div>
          <div className='vehicle__info__icon__text'>
            <img className='vehicle__info__icon' src={luggageIcon} alt='luggage' />
            <div className='vehicle__info__text'>{luggage} Luggage</div>
          </div>
        </div>
        <div className='vehicle__info__icon__text'>
          <img className='vehicle__info__icon' src={carIcon} alt='car' />
          <div className='vehicle__info__text'>{car}</div>
        </div>
      </div>
    </div>
  );
};
