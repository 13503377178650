import React from 'react';
import { bleachingImg } from '../../../../../assets/images/footer/special-services/dental-care/image/img';

export const BleachingModalComp = () => {
  return (
    <div className='modal-comp__body'>
      <div className='modal-comp__body-img'>
        <img src={bleachingImg} alt='bleaching' />
      </div>
      <div className='modal-comp__body-text'>
        <>
          <p>Achieve the whiteness you dream of in just 40 minutes.</p>
          <p>Our treatments are guaranteed for 10 years. We provide warranty certificate.</p>
          <p>
            You can get a price offer from us with free dental check-up service by coming to our
            clinic.
          </p>
        </>
      </div>
    </div>
  );
};
