import React from 'react';
import { CloseHeader, Warning } from '../../../components';
import arabic from '../../../assets/images/languages/arabic.png';
import turkish from '../../../assets/images/languages/turkish.png';
import russian from '../../../assets/images/languages/russian.png';
import english from '../../../assets/images/languages/english.png';
import { MainTitle, Pages } from '../../../helpers';

export const Language = () => {
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }

  localStorage.setItem('HomeRoute', '/language');
  localStorage.setItem('activePage', Pages.LANGUAGE);

  return (
    <div className='wrapper'>
      <div className='main-title'>{MainTitle.LANGUAGE}</div>
      <div className='content-body'>
        <CloseHeader
          title='Select Language'
          handleClick={() => window.history.back()}></CloseHeader>
        <div className='language-content'>
          <div className='language-content__languages'>
            <div className='language-content__group'>
              <div className='language-content__language'>
                <img src={english} />
                <span>English</span>
              </div>
              <div className='language-content__language'>
                <img src={turkish} />
                <span>Turkish</span>
                <span>(Coming Soon)</span>
              </div>
            </div>
            <div className='language-content__group'>
              <div className='language-content__language'>
                <img src={russian} />
                <span>Russian</span>
                <span>(Coming Soon)</span>
              </div>
              <div className='language-content__language'>
                <img src={arabic} />
                <span>Arabic</span>
                <span>(Coming Soon)</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
