import React from 'react';
import { CloseHeader, WorkingHoursBox } from './';

export const WorkingHours = ({ handleClick, hours }) => {
  return (
    <>
      <div className='content-body'>
        <CloseHeader title='Working Hours' handleClick={handleClick} />
        <WorkingHoursBox hours={hours} />
      </div>
    </>
  );
};
