import React from 'react';
import {
  Header,
  InfoBottom,
  PriceInfo,
  TransferInfo,
  NextStepButton,
  Warning,
} from '../../../components';
import { MainTitle, isSessionExpired, Vehicles } from '../../../helpers';

export const IncomingTransfer = () => {
  const validHome = localStorage.getItem('ValidHome');
  const showSessionExpired = localStorage.getItem('showSessionExpired');

  if (!validHome && !showSessionExpired) {
    return <Warning></Warning>;
  }

  if (showSessionExpired) {
    return <Warning text='Your 48 hours session has ended.'></Warning>;
  }

  const sessionDate = localStorage.getItem('SessionDate');
  const sessionExpired = isSessionExpired(sessionDate);

  if (sessionExpired) {
    localStorage.clear();
    localStorage.setItem('showSessionExpired', true);
    return <Warning text='Your 48 hours session has ended.'></Warning>;
  }

  const transferInfoStyle = {
    margin: '10px 0',
    position: 'relative',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: '100%',
    padding: '0px',
  };

  localStorage.setItem('Vehicle', JSON.stringify(Vehicles.VIP.CODE));

  return (
    <div className='wrapper'>
      <div className='main-title'>{MainTitle.INCOMING_TRANSFER}</div>
      <div className='content-body'>
        <Header title='Airport Transfer' />
        <div className='description-box' style={{ marginTop: '10px', position: 'relative' }}>
          <div className='description-box__description' style={{ padding: '10px' }}>
            <div>
              <div
                className='description-box__title'
                style={{ marginBottom: '15px', fontSize: '16px' }}>
                Hello Guest
              </div>
              <div className='description-box__body' style={{ marginBottom: '15px' }}>
                Most welcome to istanbul! We offer best and easiest VIP transportation solution for
                to go to your home
              </div>
              <TransferInfo style={transferInfoStyle} />
            </div>
          </div>
        </div>
        <InfoBottom
          title='One way transfer fee'
          contentsComp={
            <>
              <PriceInfo priceUsd={Vehicles.VIP.IST_PRICE} text='Istanbul Airport ' />
              <PriceInfo priceUsd={Vehicles.VIP.SAW_PRICE} text='Istanbul Sabiha Gokcen ' />
            </>
          }
        />
      </div>
      <NextStepButton routeUrl='/select-incoming-airport' />
    </div>
  );
};
