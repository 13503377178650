import React from 'react';
import CloseIcon from '../assets/images/icons/close-button.png';

export const CloseHeader = ({ icon, title, handleClick }) => {
  return (
    <div className='close-header-component'>
      {icon && (
        <div className='close-header-component__icon'>
          <img src={icon} alt='Icon' />
        </div>
      )}
      <div className='close-header-component__title'>{title}</div>
      <div className='close-header-component__close-icon'>
        <img src={CloseIcon} alt='Close Icon' onClick={handleClick} />
      </div>
    </div>
  );
};
