import React from 'react';
import ImageSrc from '../../../../../assets/images/massage/massage.png';
export const AntiStressMassageComp = () => {
  return (
    <div className='massage-info-componet'>
      <div className='massage-info-image-container'>
        <img
          src={ImageSrc}
          alt='massage-info-image'
          className='massage-info-image-container__image'
        />
        <div className='image-footer-info'>
          <div className='image-footer-info__title' style={{ borderRight: '1px solid white' }}>
            €75
          </div>
          <div className='image-footer-info__title'>60 min</div>
        </div>
      </div>
      <div className='massage-info-description'>
        Anti-Stress Massage is a slow and rhythmic massage technique that aims to relax and soothe
        the muscles.
      </div>
      <div className='massage-info-description'>
        Through gentle movements, the massage is performed from head to toe, allowing the muscles
        and tissues in the body to unwind.
      </div>
      <div className='massage-info-description'>
        Anti-stress massage is applied to the face, head, neck, back, and foot soles, effectively
        alleviating tension and stress.
      </div>
    </div>
  );
};
