import React from 'react';
import { CounterInput, Application } from 'react-rainbow-components';

const theme = {
  rainbow: {
    palette: {
      brand: '#FFC42E',
    },
  },
};

export const CounterInputGroup = ({ title, counter1, setCounter1, counter2, setCounter2 }) => {
  return (
    <>
      <Application theme={theme} className='counter-input-group'>
        <div className='counter-input-title'>{title}</div>
        <div className='counter-input-group__counters'>
          <CounterInput
            label='Woman'
            className='counter-input-group__counter'
            style={{ borderRight: '1px solid white' }}
            labelAlignment='center'
            value={counter1}
            onChange={setCounter1}
            min={0}
            max={99}
            variant='shaded'
            placeholder='99'
          />
          <CounterInput
            label='Man'
            className='counter-input-group__counter'
            labelAlignment='center'
            value={counter2}
            onChange={setCounter2}
            min={0}
            max={99}
            variant='shaded'
            placeholder='99'
          />
        </div>
      </Application>
    </>
  );
};
