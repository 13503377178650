import React, { useState } from 'react';
import { Header, Warning, AppBox, NextStepButton, CloseHeader } from '../../../../components';
import { MainTitle } from '../../../../helpers';
import MassageIcon from '../../../../assets/images/icons/massage-icon.png';
import {
  SwedishMassageComp,
  MedicalMassageComp,
  BaliMassageComp,
  ThaiMassageComp,
  AntiStressMassageComp,
} from './components';

export const MassageDetails = () => {
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }

  const MassageType = [
    {
      id: 1,
      imageSrc: MassageIcon,
      title: 'Swedish Massage',
      description: 'It is a type of massage that uses rhythmic strokes.',
      descriptionComp: SwedishMassageComp,
    },
    {
      id: 2,
      imageSrc: MassageIcon,
      title: 'Medical Massage',
      description: 'This practice helps relieve painful and tense muscles from toxins.',
      descriptionComp: MedicalMassageComp,
    },
    {
      id: 3,
      imageSrc: MassageIcon,
      title: 'Bali Massage',
      description:
        'Massage applied to specific points opens up blood vessels, resulting in rejuvenation of the body.',
      descriptionComp: BaliMassageComp,
    },
    {
      id: 4,
      imageSrc: MassageIcon,
      title: 'Thai Massage',
      description:
        'In the treatment, predominantly pressure and stretching movements are performed.',
      descriptionComp: ThaiMassageComp,
    },
    {
      id: 5,
      imageSrc: MassageIcon,
      title: 'Anti Stress Massage',
      description: 'Slow and rhythmic massage technique that relaxes and soothes the muscles.',
      descriptionComp: AntiStressMassageComp,
    },
  ];

  const [activeMassage, setActiveMassage] = useState(false);
  const [activeMassageId, setActiveMassageId] = useState(1);

  const setActiveMassageComp = (id) => {
    const massage = MassageType.find((massage) => massage.id === id);
    return (
      <>
        <div className='content-body'>
          <CloseHeader title={massage.title} handleClick={() => setActiveMassage(false)} />
          <massage.descriptionComp />
        </div>
      </>
    );
  };

  const handleClick = (id) => {
    setActiveMassageId(id);
    setActiveMassage(true);
  };

  return (
    <div className='wrapper'>
      <div className='main-title'>{MainTitle.MASSAGE}</div>

      {activeMassage ? (
        setActiveMassageComp(activeMassageId)
      ) : (
        <div className='content-body'>
          <Header title='Massages' />
          {MassageType.map((massage, index) => (
            <AppBox
              imageStyle={{ width: '100%', height: '100%' }}
              key={index}
              imageSrc={massage.imageSrc}
              title={massage.title}
              description={massage.description}
              handleClick={() => handleClick(massage.id)}
            />
          ))}
        </div>
      )}
      <NextStepButton routeUrl='/massage-rezervation' mainTitle={MainTitle.MASSAGE} />
    </div>
  );
};
