import React, { useState, useEffect } from 'react';
import {
  Header,
  DatePicker,
  NextStepButton,
  TransferInfo,
  TextInput,
  Warning,
} from '../../../../components';
import { MainTitle, Airports } from '../../../../helpers';

export const SelectDate = () => {
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }

  const airport = JSON.parse(localStorage.getItem('Airport'));
  const [formData, setFormData] = useState(JSON.parse(localStorage.getItem('FormData')) || {});
  const [name, setName] = useState(formData.name || '');
  const [date, setDate] = useState(formData.date || false);
  const [nameError, setNameError] = useState(false);
  const [dateError, setDateError] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value.replace(/(?:^|\s|["'([{])+\S/g, (l) => l.toUpperCase()));
    setFormData({
      ...formData,
      name: e.target.value.replace(/(?:^|\s|["'([{])+\S/g, (l) => l.toUpperCase()),
    });
    if (e.target.value) {
      setNameError(false);
    }
  };

  const handleDateChange = (value) => {
    setDate(value);
    setFormData({
      ...formData,
      date: value,
    });
    if (value) {
      setDateError(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.name || !formData.date) {
      if (!formData.name) {
        setNameError(true);
      }
      if (!formData.date) {
        setDateError(true);
      }
      return;
    }

    localStorage.setItem('MainTitle', MainTitle.TRANSFER);
    window.location.href = '/request';
  };

  useEffect(() => {
    localStorage.setItem('FormData', JSON.stringify(formData));
  }, [formData]);

  return (
    <>
      <div className='wrapper'>
        <div className='main-title'>{MainTitle.TRANSFER}</div>
        <div className='content-body'>
          <Header title={Airports[airport].CODE} subTitle={Airports[airport].NAME} />
          <div className='request-button-group' style={{ height: 'calc(100% - 130px)' }}>
            <DatePicker
              placeholder='When do you want?'
              value={date}
              handleChange={handleDateChange}
              required={dateError}
            />
            <TextInput
              required={nameError}
              placeholder='Name & Surname'
              style={{ marginBottom: '20px' }}
              handleChange={handleNameChange}
              value={name}
            />
            <div className='description-box__transfer'>
              <div className='description-box__desc'>
                Estimated a time is <b>{Airports[airport].TIME}.</b>
              </div>
              <div className='description-box__sub-desc'>
                We recommend that you choose <b>min 3 hours</b> before the flight.
              </div>
            </div>
          </div>
          <TransferInfo />
        </div>
        <NextStepButton handleClick={handleSubmit} />
      </div>
    </>
  );
};
