import {
  sava,
  petshop,
  nergis_fish_galata,
  loba,
  kuafor,
  okeep_ofice,
  clinident,
} from '../assets/images/footer/offers/images/image';

export const MainTitle = {
  TRANSFER: 'Transportation',
  INCOMING_TRANSFER: 'Transportation ',
  CLEANING: 'Home Cleaning',
  DENTAL: 'Dental Care',
  HAMAM: 'Hamam',
  MASSAGE: 'Massage',
  USEFUL_INFO: 'Useful Information',
  CHOOSE_SERVICE: 'Choose Services',
  HOME_INFO: 'Home Info',
  CHOOSE_SPECIAL_SERVICE: 'Choose Special Services',
  CHOOSE_HOME_SERVICE: 'Choose Home Services',
  LATE_CHECKOUT: 'Late Check-Out',
  OFFERS: 'Offers',
  ROOM_SUPPLIES: 'Room Supplies',
  TAXI_TRANSFER: ' Transportation ',
  SPECIAL_REQUEST: 'Special Request',
  LANGUAGE: 'Language',
  SUPPORT: 'Support',
  MARKET: 'Market',
};

export const CagalogluInfo = {
  LOCATION_URL:
    'https://www.google.com/maps/place/Ca%C4%9Falo%C4%9Flu+Hamam%C4%B1/@41.0105944,28.9753372,15z/data=!4m6!3m5!1s0x14cab995891a495d:0xaf74079aa7195acc!8m2!3d41.0105944!4d28.9753372!16s%2Fm%2F04y6x9x?sa=X&ved=2ahUKEwiqhZ7A2fqAAxVWXfEDHT4CD_EQ_BJ6BAhdEAA&ved=2ahUKEwiqhZ7A2fqAAxVWXfEDHT4CD_EQ_BJ6BAh4EAg&entry=tts&shorturl=1',
  LATITUDE: 41.0106,
  LONGITUDE: 28.97534,
  INSTAGRAM_URL: 'https://www.instagram.com/cagalogluhamam/',
};

export const CagalogluWorkingHours = {
  WEEKDAYS: '09:00 - 22:00',
  SATURDAY: '09:00 - 22:00',
  SUNDAY: '09:00 - 22:00',
};

export const ClinidentInfo = {
  LOCATION_URL:
    'https://www.google.com/maps/place/Clinident+Ni%C5%9Fanta%C5%9F%C4%B1/@41.04817,28.99325,17z/data=!4m12!1m6!3m5!1s0x14cab70c817b0f03:0xc49e48649bd81d93!2zQ2xpbmlkZW50IE5pxZ9hbnRhxZ_EsQ!8m2!3d41.0480364!4d28.9932639!3m4!1s0x14cab70c817b0f03:0xc49e48649bd81d93!8m2!3d41.0480364!4d28.9932639?hl=en-US',
  LATITUDE: 41.04817,
  LONGITUDE: 28.99325,
  WHATSAPP_API_URL: 'https://api.whatsapp.com/send/?phone=905433296292&text=',
  INSTAGRAM_URL: 'https://www.instagram.com/clinident_nisantasi/',
  TITLE: 'Clinident Nişantaşı',
  DESCRIPTION: 'Clinident Nişantaşı',
  DISCOUNT: 'Free Dental Check-Up',
  IMAGE: clinident,
};

export const ClinidentWorkingHours = {
  WEEKDAYS: '09:00 - 19:00',
  SATURDAY: '09:00 - 13:00',
  SUNDAY: 'Closed',
};

export const CihangirMassageInfo = {
  LOCATION_URL:
    'https://www.google.com/maps/place/PASHA+SPA%26WELLNESS/@41.03667,28.99054,17z/data=!3m1!4b1!4m6!3m5!1s0x14cab76f5e51ee67:0x2ef8da1be0afd737!8m2!3d41.0366697!4d28.9905429!16s%2Fg%2F11b6hqg756?hl=en-US',
  LATITUDE: 41.03667,
  LONGITUDE: 28.99054,
  INSTAGRAM_URL: 'https://www.instagram.com/pashaspawellness/',
};

export const SaVaBreakfastInfo = {
  LOCATION_URL:
    'https://www.google.com/maps/place/Sa+Va+Anatolian+Breakfast+House/@41.0321595,28.9835649,15z/data=!4m6!3m5!1s0x14cab7564bb1c8bd:0x4c668a7b26f6e825!8m2!3d41.0321595!4d28.9835649!16s%2Fg%2F11sddb0g5v?entry=ttu',
  LATITUDE: 41.0321595,
  LONGITUDE: 28.9835649,
  INSTAGRAM_URL: 'https://www.instagram.com/savaanatolian/',
  TITLE: 'Sa Va Anatolian Breakfast House',
  DESCRIPTION: 'Sa Va Anatolian Breakfast House',
  DISCOUNT: '%30 discount',
  IMAGE: sava,
};

export const NergisFishGalataInfo = {
  LOCATION_URL:
    'https://www.google.com/maps/place/Nergis+Bal%C4%B1k+Galata/@41.0188639,28.9725816,15z/data=!4m6!3m5!1s0x14cab914b7b3ef5d:0x8e2081cf5f13a292!8m2!3d41.0188639!4d28.9725816!16s%2Fg%2F11v13ry8wc?entry=ttu',
  LATITUDE: 41.01886,
  LONGITUDE: 28.97258,
  INSTAGRAM_URL: 'https://www.instagram.com/galata_nergis/',
  TITLE: 'Nergis Fish Galata',
  DESCRIPTION: 'Nergis Fish Galata',
  DISCOUNT: '%30 discount',
  IMAGE: nergis_fish_galata,
};

export const LobaInfo = {
  LOCATION_URL:
    "https://www.google.com/maps/place/L'oba+Coffee+House/@41.032195,28.9817152,17z/data=!3m1!4b1!4m6!3m5!1s0x14cab7cae80e3665:0xc2fcb52357644791!8m2!3d41.032195!4d28.9842901!16s%2Fg%2F11vc0gnb2b?entry=ttu",
  LATITUDE: 41.03219,
  LONGITUDE: 28.98429,
  INSTAGRAM_URL: 'https://www.instagram.com/lobacoffeehouse/',
  TITLE: "L'oba Coffee House",
  DESCRIPTION: "L'oba Coffee House",
  DISCOUNT: '%13 discount',
  IMAGE: loba,
};

export const MaskulenInfo = {
  LOCATION_URL:
    'https://www.google.com/maps/place/Mask%C3%BClen+Cihangir+kuaf%C3%B6r/@41.0295146,28.9819594,15z/data=!4m6!3m5!1s0x14cab98ac8eb4af1:0x3683f35c0f7771f!8m2!3d41.0295146!4d28.9819594!16s%2Fg%2F11fhy2gbrw?entry=ttu',
  LATITUDE: 41.02952,
  LONGITUDE: 28.98194,
  INSTAGRAM_URL: 'https://www.instagram.com/maskulencihangir/',
  TITLE: "Maskülen Cihangir men's hairdresser",
  DESCRIPTION: "Maskülen Cihangir men's hairdresser",
  DISCOUNT: '%10 discount',
  IMAGE: kuafor,
};

export const KarakediInfo = {
  LOCATION_URL:
    'https://www.google.com/maps/place/Karakedi+Petshop/@41.0321931,28.9636905,14z/data=!4m10!1m2!2m1!1skarakedi!3m6!1s0x14cab7f019de7bcf:0x6a7b4270ce74dec2!8m2!3d41.03232!4d28.9844971!15sCghrYXJha2VkaVoKIghrYXJha2VkaZIBEHBldF9zdXBwbHlfc3RvcmXgAQA!16s%2Fg%2F11t2_93tgz?entry=ttu',
  LATITUDE: 41.03232,
  LONGITUDE: 28.9845,
  INSTAGRAM_URL: 'https://www.instagram.com/cihangirkarakedi/',
  TITLE: 'Karakedi Petshop',
  DESCRIPTION: 'Karakedi Petshop',
  DISCOUNT: '%10 discount',
  IMAGE: petshop,
};

export const WorkSpaceInfo = {
  LOCATION_URL:
    'https://www.google.com/maps?q=Cihangir,+Oba+Sk.+No:+2+D:B,+34433+Beyo%C4%9Flu/%C4%B0stanbul&ftid=0x14cab763add3b875:0x8569662e171eecb5&hl=tr-TR&gl=tr&entry=gps&lucs=,47071704,47086682,47069508,47084304&g_ep=CAISDDYuOTYuMS4zMDU4MBgAIJ6dCiokLDQ3MDcxNzA0LDQ3MDg2NjgyLDQ3MDY5NTA4LDQ3MDg0MzA0QgJUUg%3D%3D&g_st=ic',
  LATITUDE: 41.03256390985454,
  LONGITUDE: 28.98429954232808,
  INSTAGRAM_URL: 'https://www.instagram.com/okeepco/',
  TITLE: 'Okeep Workspace',
  DESCRIPTION: 'If you need a workspace, you can use okeep workspace between 10:00 and 20:00.',
  DISCOUNT: 'Free',
  IMAGE: okeep_ofice,
};

export const CihangirMassageWorkingHours = {
  WEEKDAYS: '11:00 - 00:00',
  SATURDAY: '11:00 - 00:00',
  SUNDAY: '11:00 - 00:00',
};

export const OkeepInfo = {
  TELEGRAM_API_URL: 'https://t.me/a43a593843164bcf8d22e8b8040e',
  WHATSAPP_API_URL: 'https://api.whatsapp.com/send/?phone=905367724858&text=',
  TELEGRAM_BOT_URL:
    'https://api.telegram.org/bot5479857834:AAHe2zSfDNodYYxHNnSXX7K1OkpwLZfaH1k/sendMessage?chat_id=@a43a593843164bcf8d22e8b8040e&text=',
  INSTAGRAM_URL: 'https://www.instagram.com/okeepco/',
};

export const ExternalLinks = {
  EXCHANCE_URL:
    'https://api.freecurrencyapi.com/v1/latest?apikey=fca_live_wp56I4LHM7SqHLf889RHHm4n8rxuPZQiUXgRa5lv',
  GOOGLE_MAPS_API_KEY: 'AIzaSyCOY6W6ec7Gkl5qtYmIimnKtLtQxAZGNes',
};

export const Airports = {
  SAW: {
    NAME: 'Istanbul Sabiha Gökçen',
    CODE: 'SAW',
    TIME: '1 hour',
  },
  IST: {
    NAME: 'Istanbul Airport',
    CODE: 'IST',
    TIME: '45 min',
  },
};

export const Vehicles = {
  VIP: {
    NAME: 'VIP Taxi',
    CODE: 'VIP',
    IST_PRICE: 45,
    SAW_PRICE: 50,
    PERSON: 6,
  },
  TAXI: {
    NAME: 'Yellow Taxi',
    CODE: 'TAXI',
    IST_PRICE: 30,
    SAW_PRICE: 35,
    PERSON: 3,
  },
};

export const DeviceTypes = {
  IOS: 'IOS',
  ANDROID: 'ANDROID',
  OTHER: 'OTHER',
};

export const Pages = {
  HOME_SERVICES: 'HOME_SERVICES',
  SPECIAL_SERVICES: 'SPECIAL_SERVICES',
  OFFERS: 'OFFERS',
  LANGUAGE: 'LANGUAGE',
  INFO: 'INFO',
};
