import React from 'react';
import ImageSrc from '../../../../../assets/images/massage/massage.png';
export const ThaiMassageComp = () => {
  return (
    <div className='massage-info-componet'>
      <div className='massage-info-image-container'>
        <img
          src={ImageSrc}
          alt='massage-info-image'
          className='massage-info-image-container__image'
        />
        <div className='image-footer-info'>
          <div className='image-footer-info__title' style={{ borderRight: '1px solid white' }}>
            €90
          </div>
          <div className='image-footer-info__title'>60 min</div>
        </div>
      </div>
      <div className='massage-info-description'>
        Thai Massage, also known as Thai Yoga Massage, is one of the traditional healing arts
        practiced in Thailand for over 2,500 years. It is an application that aims to enhance human
        health both mentally and physically, based on the philosophy and wisdom of the Far East.
      </div>
      <div className='massage-info-description'>
        In Thai Massage, primarily pressure and stretching movements are performed. The practice is
        entirely natural and focuses on holistic healing.
      </div>
    </div>
  );
};
