import React from 'react';
import { getTimeString, getDateString, Vehicles, Airports } from '../helpers';

export const TransferRequest = ({ setMessage }) => {
  const home = JSON.parse(localStorage.getItem('Home'));
  const formData = JSON.parse(localStorage.getItem('FormData'));
  const timeString = getTimeString(formData.date);
  const dateString = getDateString(formData.date);
  const vehicle = JSON.parse(localStorage.getItem('Vehicle'));
  const airport = JSON.parse(localStorage.getItem('Airport'));

  const from = `From: ${home.title}`;
  const request = 'Request: Airport Transfer';
  const prefer = `Prefer: ${Vehicles[vehicle].NAME}`;
  const name = `Name: ${formData.name}`;
  const location = `Airport: ${Airports[airport].NAME}`;
  const arriveTime = `Arrive Time: ${timeString} / ${dateString}`;
  const address = `Address: ${home.address}`;
  const message = `${from}\n${request}\n${prefer}\n${name}\n${location}\n${arriveTime}\n${address}`;

  setMessage(encodeURIComponent(message));

  return (
    <div className='request-info'>
      <div className='request-info__text'>Hello Okeep,</div>
      <div className='request-info__text'>
        I{"'"}m <span>{formData.name}</span>
      </div>
      <div className='request-info__text'>
        I request a transfer service to go to <span>{Airports[airport].NAME}</span> with{' '}
        <span>{Vehicles[vehicle].NAME}</span>
      </div>
      <div className='request-info__text'>
        at <span>{timeString}</span> on <span>{dateString}</span>
      </div>
    </div>
  );
};
