export const AppBox = ({ imageSrc, title, description, handleClick, imageStyle }) => {
  return (
    <div className='app-box' onClick={handleClick}>
      <div className='app-box__image-container'>
        <img className='app-box__image' src={imageSrc} alt='app-icon' style={imageStyle} />
      </div>
      <div className='app-box__info-box'>
        <div className='app-box__info-box__title'>{title}</div>
        <div className='app-box__info-box__description'>{description}</div>
      </div>
    </div>
  );
};
