import React from 'react';
import { implantImg } from '../../../../../assets/images/footer/special-services/dental-care/image/img';

export const ImplantModalComp = () => {
  return (
    <div className='modal-comp__body'>
      <div className='modal-comp__body-img'>
        <img src={implantImg} alt='implant' />
      </div>
      <div className='modal-comp__body-text'>
        <>
          <p>We work with dentists who are experts in their field.</p>
          <p>
            We only work with implant companies that promise the highest quality and longevity.
            (Astra, Straummaun, Bego, Nobel, Ostan etc.)
          </p>
          <p>Our treatments are guaranteed for 10 years. We provide warranty certificate.</p>
          <p>
            You can get a price offer from us with free dental check-up service by coming to our
            clinic.
          </p>
        </>
      </div>
    </div>
  );
};
