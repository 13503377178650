import React from 'react';

export const ExtraPackage = ({ title, description, price }) => {
  return (
    <div className='extra-package-container'>
      <div className='package-header'>
        <div className='extra-package-container__title'>{title}</div>
        <div className='extra-package-container__price'>+ {price}</div>
      </div>

      <div className='extra-package-container__info-box'>
        {/* <Application theme={theme}>
          <Input
            className='extra-package-container__info-box__radio-button'
            type='radio'
            value={value}
            onClick={handleChange}
            checked={isActive === value}
          />
        </Application> */}
        <div className='extra-package-container__info-box__body'>{description}</div>
      </div>
    </div>
  );
};
