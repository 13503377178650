import React from 'react';
import Modal from '@mui/material/Modal';
import { CloseHeader, RequestButton } from '../components';
import { whatsappClinidentActions } from '../helpers';
import whatsappIcon from '../assets/images/icons/whatsapp.png';

export const InfoModal = ({ title, ModalComp, openModal, closeModal }) => {
  const message = `I'm writing from Okeep, I would like to learn about "${title}"`;
  return (
    <div>
      <Modal
        open={openModal}
        onClose={closeModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <div className='modal-container'>
          <CloseHeader title={title} handleClick={closeModal} />
          <div className='modal-body'>{ModalComp}</div>
          <div className='bottom-button-group'>
            <RequestButton
              text='Ask Questions 7/24'
              iconSrc={whatsappIcon}
              onClick={() => whatsappClinidentActions(message)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
