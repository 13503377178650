import { Header, Warning, SuppliesCompList, NextStepButton } from '../../../../components';
import { MainTitle } from '../../../../helpers';
import { roomSuppliesImg } from '../../../../assets/images/footer/home-services/room-supplies/img';

const suppliesList = [
  {
    iconSrc: roomSuppliesImg,
    price: 'Free',
    product: 'Towel',
    info: 'It is a type of massage that uses rhythmic strokes.',
  },
  {
    iconSrc: roomSuppliesImg,
    price: 'Free',
    product: 'Shampoo & Shower Gel',
    info: 'This practice helps relieve painful and tense muscles from toxins..',
  },
  {
    iconSrc: roomSuppliesImg,
    price: 'Free',
    product: 'Toilet Paper & Tissue',
    info: 'Applied to specific points opens up blood vessels, resulting in rejuvenation of the body.',
  },
  {
    iconSrc: roomSuppliesImg,
    price: '5 €',
    product: 'Universal Charge Adapter',
    info: 'In the treatment, predominantly pressure and stretching movements are performed.',
  },
  {
    iconSrc: roomSuppliesImg,
    price: 'Free',
    product: 'Water',
    info: 'Slow and rhythmic massage technique that relaxes and soothes the muscles.',
  },
];

export const RoomSupplies = () => {
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }

  return (
    <div className='wrapper'>
      <div className='main-title'>{MainTitle.ROOM_SUPPLIES}</div>
      <div className='content-body'>
        <Header title='Choose Supplies' subTitle='How many do you need?' />
        <div className='supplies-component'>
          {suppliesList.map((item, index) => (
            <SuppliesCompList
              key={index}
              iconSrc={item.iconSrc}
              product={item.product}
              price={item.price}
              info={item.info}
            />
          ))}
        </div>
      </div>
      <NextStepButton routeUrl='/request' mainTitle={MainTitle.ROOM_SUPPLIES} />
    </div>
  );
};
