import React from 'react';
import arrow from '../assets/images/icons/arrow.png';

export const NextStepButton = ({
  text = 'Continue',
  routeUrl,
  iconSrc = arrow,
  mainTitle,
  handleClick = () => {
    localStorage.setItem('MainTitle', mainTitle);
    window.location.href = routeUrl;
  },
}) => {
  return (
    <div className='next-step-button' onClick={handleClick} type='submit'>
      <div className='next-step-button__text'>{text}</div>
      <div className='next-step-button__icon'>
        <img src={iconSrc} alt='next icon' />
      </div>
    </div>
  );
};
