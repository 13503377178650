import { motion } from 'framer-motion';
import React from 'react';

const LoadingDot = (color) => ({
  background: color,
  display: 'block',
  width: '2rem',
  height: '2rem',
  borderRadius: '50%',
});

const LoadingContainer = {
  width: '8rem',
  height: '4rem',
  display: 'flex',
  justifyContent: 'space-around',
};

const ContainerVariants = {
  initial: {
    transition: {
      staggerChildren: 0.2,
    },
  },
  animate: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const DotVariants = {
  initial: {
    y: '0%',
  },
  animate: {
    y: '100%',
  },
};

const DotTransition = {
  duration: 0.5,
  repeat: Infinity,
  repeatType: 'reverse',
  ease: 'easeInOut',
};

export const Loader = () => {
  return (
    <div
      style={{
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
        position: 'absolute',
        zIndex: '1',
        backgroundColor: 'white',
      }}>
      <motion.div
        style={LoadingContainer}
        variants={ContainerVariants}
        initial='initial'
        animate='animate'>
        <motion.span
          style={LoadingDot('#ec585a')}
          variants={DotVariants}
          transition={DotTransition}
        />
        <motion.span
          style={LoadingDot('#a8a8a8')}
          variants={DotVariants}
          transition={DotTransition}
        />
        <motion.span
          style={LoadingDot('#f5bd49')}
          variants={DotVariants}
          transition={DotTransition}
        />
      </motion.div>
    </div>
  );
};
