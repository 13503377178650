import React from 'react';
import info from '../assets/images/icons/homeInfoIcon/info.png';
import logo from '../assets/images/okeepLogo.png';
import languages from '../assets/images/icons/homeInfoIcon/languages.png';
import { Pages } from '../helpers';

export const MenuBar = () => {
  const activePage = localStorage.getItem('activePage');

  const handleClickInfo = () => {
    localStorage.setItem('HomeRoute', '/home-info');
    localStorage.setItem('activePage', Pages.INFO);
    window.location.href = '/home-info';
  };

  const handleClickLanguage = () => {
    localStorage.setItem('HomeRoute', '/language');
    localStorage.setItem('activePage', Pages.LANGUAGE);
    window.location.href = '/language';
  };

  const handleClickLogo = () => {
    localStorage.setItem('HomeRoute', '/');
    localStorage.setItem('activePage', Pages.HOME_SERVICES);
    window.location.href = '/';
  };

  return (
    <div className='menu-bar-component'>
      <div
        className={`menu-bar menu-bar__border ${activePage === Pages.INFO && 'active'}`}
        onClick={handleClickInfo}>
        <div className='menu-bar__logo'>
          <img src={info} alt='info' />
        </div>
        <div className='menu-bar__title'>Info</div>
      </div>
      <div className='menu-bar menu-bar__border' onClick={handleClickLogo}>
        <div className='okeep-logo'>
          <img src={logo} alt='logo' />
        </div>
      </div>
      <div className={`menu-bar ${activePage === Pages.LANGUAGE && 'active'}`}>
        <div className='menu-bar__logo'>
          <img src={languages} alt='lang' />
        </div>
        <div className='menu-bar__title' onClick={handleClickLanguage}>
          Language
        </div>
      </div>
    </div>
  );
};
