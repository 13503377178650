import React from 'react';
import { hollywoodSmileImg } from '../../../../../assets/images/footer/special-services/dental-care/image/img';

export const HollyWoodModalComp = () => {
  return (
    <div className='modal-comp__body'>
      <div className='modal-comp__body-img'>
        <img src={hollywoodSmileImg} alt='hollywood smile' />
      </div>
      <div className='modal-comp__body-text'>
        <>
          <p>
            Our clinic, which has all the modern and technological infrastructure, promises you the
            most beautiful smile in 4 days.
          </p>
          <p>
            Our treatments are guaranteed for 10 years and we issue a certificate that shows
            treatment is valid for 10 years.
          </p>
          <p>You can get a price offer by sending us your photo without going to our clinic.</p>
          <p>
            We create the best smile for you using 3D modeling programs. We can show you before
            applying.
          </p>
        </>
      </div>
    </div>
  );
};
