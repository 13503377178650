import React from 'react';
import { CheckboxToggle, Application } from 'react-rainbow-components';

const theme = {
  rainbow: {
    palette: {
      brand: '#FFC42E',
    },
  },
};

export const CheckboxToggleInput = ({ title, value, onChange }) => {
  return (
    <div className='checkbox-container'>
      <div className='checkbox-container__title'>{title}</div>
      <Application theme={theme} className='checkbox-container__input'>
        <CheckboxToggle
          className='checkbox-container__checkbox'
          id='checkbox-toggle-component-1'
          value={value}
          onChange={onChange}
        />
      </Application>
    </div>
  );
};
