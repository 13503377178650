import React, { useState, useEffect } from 'react';
import { Header, DatePicker, NextStepButton, TextInput, Warning } from '../../../components';
import { MainTitle, Airports } from '../../../helpers';
import infoIcon from '../../../assets/images/icons/info.png';

export const IncomingGuestInfo = () => {
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }

  const airport = JSON.parse(localStorage.getItem('Airport'));
  const [formData, setFormData] = useState(JSON.parse(localStorage.getItem('FormData')) || {});
  const [name, setName] = useState(formData.name || '');
  const [date, setDate] = useState(formData.date || false);
  const [flightNumber, setFlightNumber] = useState(formData.flightNumber || '');

  const handleNameChange = (e) => {
    setName(e.target.value.replace(/(?:^|\s|["'([{])+\S/g, (l) => l.toUpperCase()));
    setFormData({
      ...formData,
      name: e.target.value.replace(/(?:^|\s|["'([{])+\S/g, (l) => l.toUpperCase()),
    });
  };

  const handleFlightNumberChange = (e) => {
    setFlightNumber(e.target.value.toUpperCase());
    setFormData({
      ...formData,
      flightNumber: e.target.value.toUpperCase(),
    });
  };

  const handleDateChange = (value) => {
    setDate(value);
    setFormData({
      ...formData,
      date: value,
    });
  };

  useEffect(() => {
    localStorage.setItem('FormData', JSON.stringify(formData));
  }, [formData]);

  return (
    <div className='wrapper'>
      <div className='main-title'>{MainTitle.INCOMING_TRANSFER}</div>
      <div className='content-body'>
        <Header title={Airports[airport].CODE} subTitle={Airports[airport].NAME} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'calc(100% - 165px)',
            width: '100%',
          }}>
          <DatePicker
            placeholder='When do you want?'
            value={date}
            handleChange={handleDateChange}
          />
          <TextInput
            placeholder='Name & Surname'
            style={{ marginBottom: '10px' }}
            handleChange={handleNameChange}
            value={name}
          />
          <TextInput
            placeholder='Enter your flight number'
            handleChange={handleFlightNumberChange}
            value={flightNumber}
          />
          <div className='more-information-container'>
            <img className='info-icon' src={infoIcon} alt='Info Icon' />
            <span className='more-information-text'>E.g. “TK2412”, “PC2204”</span>
          </div>
        </div>

        <div className='bottom-info-container'>
          <div className='description'>
            <span style={{ display: 'flex', margin: '5px 0' }}>
              This helps us calculate your pick-up time and lets the driver track your arrival.
            </span>
            <span style={{ display: 'flex', margin: '5px 0' }}>
              You can usually find this on a confirmation email from the company you booked with
              (E.g. “TK2412”, “PC2204”)
            </span>
          </div>
        </div>
      </div>
      <NextStepButton routeUrl='/request' mainTitle={MainTitle.INCOMING_TRANSFER} />
    </div>
  );
};
