import React, { useState, useEffect } from 'react';
import {
  Header,
  DatePicker,
  NextStepButton,
  TransferInfo,
  TextInput,
  Warning,
} from '../../../../components';
import { MainTitle } from '../../../../helpers';

export const TaxiReservation = () => {
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }

  const [formData, setFormData] = useState(JSON.parse(localStorage.getItem('FormData')) || {});
  const [name, setName] = useState(formData.name || '');
  const [date, setDate] = useState(formData.date || false);
  const [location, setLocation] = useState(formData.location || '');
  const [nameError, setNameError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [locationError, setLocationError] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value.replace(/(?:^|\s|["'([{])+\S/g, (l) => l.toUpperCase()));
    setFormData({
      ...formData,
      name: e.target.value.replace(/(?:^|\s|["'([{])+\S/g, (l) => l.toUpperCase()),
    });
    if (e.target.value) {
      setNameError(false);
    }
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value.replace(/(?:^|\s|["'([{])+\S/g, (l) => l.toUpperCase()));
    setFormData({
      ...formData,
      location: e.target.value.replace(/(?:^|\s|["'([{])+\S/g, (l) => l.toUpperCase()),
    });
    if (e.target.value) {
      setLocationError(false);
    }
  };

  const handleDateChange = (value) => {
    setDate(value);
    setFormData({
      ...formData,
      date: value,
    });
    if (value) {
      setDateError(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.name || !formData.date) {
      if (!formData.name) {
        setNameError(true);
      }
      if (!formData.date) {
        setDateError(true);
      }
      if (!formData.location) {
        setDateError(true);
      }
      return;
    }

    localStorage.setItem('MainTitle', MainTitle.TAXI_TRANSFER);
    window.location.href = '/request';
  };

  useEffect(() => {
    localStorage.setItem('FormData', JSON.stringify(formData));
  }, [formData]);

  return (
    <>
      <div className='wrapper'>
        <div className='main-title'>{MainTitle.TAXI_TRANSFER}</div>
        <div className='content-body'>
          <Header title='Form' />
          <div className='request-button-group' style={{ height: 'calc(100% - 130px)' }}>
            <TextInput
              required={nameError}
              placeholder='Name & Surname'
              handleChange={handleNameChange}
              value={name}
            />
            <TextInput
              required={locationError}
              placeholder='Where do you want to go?'
              handleChange={handleLocationChange}
              value={location}
              style={{ margin: '10px 0' }}
            />
            <div className='description-box__transfer'>
              <div className='description-box__sub-desc'>Write any place you want to go</div>
            </div>

            <DatePicker
              placeholder='When do you want?'
              value={date}
              handleChange={handleDateChange}
              required={dateError}
            />

            <div className='description-box__transfer'>
              <div className='description-box__sub-desc'>
                We recommend that you choose <b>min 1 hour</b> before you want.
              </div>
            </div>
          </div>
          <TransferInfo />
        </div>
        <NextStepButton handleClick={handleSubmit} />
      </div>
    </>
  );
};
